import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const PageModal = ({isOpen, handleClose, title, ok, cancel, children}) => {


  return (
  
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg">
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle> }
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('cancel')} variant="contained" autoFocus>
            {cancel}
          </Button>
          <Button onClick={() => handleClose('ok')} color="primary" variant="contained">
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    
  );
}