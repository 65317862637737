export const salaryCalculatorFields = [

    { 
      field: 'employeeName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'You need to enter employee name',
      validationStage: 'Initial Salary'
    },
    { 
      field: 'brutoSalary', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Bruto salary shouldn\'t be empty',
      validationStage: 'Initial Salary'
    },
    { 
      field: 'netoSalary', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Neto salary shouldn\'t be empty',
      validationStage: 'Initial Salary'
    },
  ]

