import React from "react";
import { withTranslation } from 'react-i18next';

import Input from '@material-ui/core/Input';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {  getNum, numToString, validateNumberString } from '../CreditCalculatorPage/formulas';

class AdditionalCosts extends React.Component {
  constructor(props) {
    super(props);
    const newAdditionallCosts = props.initial_additional_costs;
    //calculate totals
    for (var i in newAdditionallCosts) {
      let periodCount = 0;
      if (newAdditionallCosts[i].value > 0 && newAdditionallCosts[i].unit && newAdditionallCosts[i].period) {
        if (newAdditionallCosts[i].period === 'm') {
          periodCount = getNum(this.props.installment_num);
        } else if (newAdditionallCosts[i].period === 'y') {
          periodCount = getNum(this.props.installment_num) / 12;
        }
        newAdditionallCosts[i].unit === '%' ?
          newAdditionallCosts[i].total = ((getNum(this.props.propertyPrice) * newAdditionallCosts[i].value) / 100) * periodCount
          : newAdditionallCosts[i].total = newAdditionallCosts[i].value * periodCount
        
        newAdditionallCosts[i].valid = true;
        newAdditionallCosts[i].valueStr = numToString(newAdditionallCosts[i].value);

      }
    }
    this.state = {
      additionallCosts: newAdditionallCosts 

    };
  }

  sumTotalAdditionalCosts = () => {
    const initialValue = 0;
    var sum = this.state.additionallCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total || 0,
      initialValue
    );
    return sum;
  }

  handleAdditionallCostChange = (idx, propName) => evt => {
    const newAdditionallCosts = this.state.additionallCosts.map((additionallCost, sidx) => {
      let valid = additionallCost.valid;
      if (idx !== sidx) return additionallCost;
      if (propName === 'value') {
        valid = validateNumberString(evt.target.value);
        return { ...additionallCost, value: getNum(evt.target.value), valueStr:evt.target.value, valid}
      } else return { ...additionallCost, [propName]: evt.target.value, valid };
    });

    for (var i in newAdditionallCosts) {
      let periodCount = 0;
      if (newAdditionallCosts[i].value > 0 && newAdditionallCosts[i].unit && newAdditionallCosts[i].period) {
        if (newAdditionallCosts[i].period === 'm') {
          periodCount = getNum(this.props.installment_num);
        } else if (newAdditionallCosts[i].period === 'y') {
          periodCount = getNum(this.props.installment_num) / 12;
        }
        newAdditionallCosts[i].unit === '%' ?
          newAdditionallCosts[i].total = ((this.props.propertyPrice * getNum(newAdditionallCosts[i].value)) / 100) * periodCount
          : newAdditionallCosts[i].total = newAdditionallCosts[i].value * periodCount

      }
    }

    this.setState({ additionallCosts: newAdditionallCosts });
    this.props.handle(newAdditionallCosts);//send state to parent component
  };



  handleAddAdditionallCost = () => {
    let i = 0;
    if (this.state.additionallCosts.length > 0) {
      i = this.state.additionallCosts[this.state.additionallCosts.length-1].id + 1;
    }
    this.setState({
      additionallCosts: this.state.additionallCosts.concat([{ id: i, 
        name: '', value: '', unit: '€', period: 'm', total: '' }])
    });
  };

  handleRemoveAdditionallCost = idx => () => {
    const newAdditionallCosts = this.state.additionallCosts.filter((s, sidx) => idx !== sidx);
    this.setState({
      additionallCosts: newAdditionallCosts
    });
    this.props.handle(newAdditionallCosts);
  };

  render() {
    const t = this.props.t;
    return (
      <form className="gridlist">
        {this.state.additionallCosts.map((additionallCost, idx) => (
          <div className="gridlist__row" key={additionallCost.id}>
            <div className="gridlist__cell">
              {idx + 1}.
            </div>
            <div className="gridlist__cell">
              <Input
                type="text"
                placeholder={t('Cost Name')}
                value={additionallCost.name}
                onChange={this.handleAdditionallCostChange(idx, 'name')}
              />
            </div>
            <div className="gridlist__cell">
              <Input
                placeholder={t('Price')}
                value={additionallCost.valueStr || ''}
                onChange={this.handleAdditionallCostChange(idx, 'value')}
              />
              {this.props.invalid && additionallCost.valid === false &&
                <div className="help-block">{t('Number not valid')}</div>}
            </div>
            <div className="gridlist__cell">
              <Select
                value={additionallCost.unit}
                onChange={this.handleAdditionallCostChange(idx, 'unit')}>
                <MenuItem value="€">€</MenuItem>
                <MenuItem value="%">%</MenuItem>
              </Select>
            </div>
            <div className="gridlist__cell">
              <Select
                value={additionallCost.period}
                onChange={this.handleAdditionallCostChange(idx, 'period')}>
                <MenuItem value="m">{t('Monthly')}</MenuItem>
                <MenuItem value="y">{t('Yearly')}</MenuItem>
              </Select>
            </div>
            <span className="gridlist__cell number row-total">{numToString(additionallCost.total)}</span>

            <div className="gridlist__cell">
              <Fab color="secondary" aria-label="Add" onClick={this.handleRemoveAdditionallCost(idx)}>
                <DeleteIcon />
              </Fab>
            </div>
          </div>
        ))}
        <div className="gridlist__row">
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell">
            <Fab color="primary" aria-label="Add" onClick={this.handleAddAdditionallCost}>
              <AddIcon />
            </Fab>
          </div>
        </div>

        <div className="gridlist__row">
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist_cell number subtotal-field">
            <span className="amount">
              {numToString(this.sumTotalAdditionalCosts())}
            </span>
          </div>
          <div className="gridlist__cell"></div>
        </div>
      </form>
    );
  }
}

const AdditionalCostsWithTranslation = withTranslation('global')(AdditionalCosts);
export { AdditionalCostsWithTranslation as AdditionalCosts };

