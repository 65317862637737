import React from 'react';
import { withTranslation } from 'react-i18next';
import BrokenImg from '../brokenimg.svg';


class NotFoundPage extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div className="broken-page">
        <img className="broken-img" src={BrokenImg} alt="404" width="200" />
        <h2>{t('Nothing to see here...')}</h2>
        <p>{t('Lets start')} <a href="/">{t('over')}</a>.</p>
      </div>
    )
  }
}


export default NotFoundPage = withTranslation('global')(NotFoundPage);
//NotFoundPage;//