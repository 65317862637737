
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';
// the translations
import translationEN from '../_translations/en/global.json';
import translationSR from '../_translations/sr/global.json';
const numeral = require('numeral');

const resources = {
  en: {
    global: translationEN //global is the namespace to reffer in app
  },
  sr: {
    global: translationSR //global is the namespace to reffer in app
  }
};
i18n
  .use(LngDetector) 
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'sr',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });
  export default i18n;

  export const dateFormat = {
    sr: 'D.M.YYYY',
    en: 'M/D/YYYY'
  }
  
  export const numberFormats = {
    sr: {
      delimiters: {
          thousands: '.',
          decimal: ','
      },
      abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
      },
      ordinal : function (number) {
          return number === 1 ? '' : '';
      },
      currency: {
          symbol: 'RSD'
      }
    },
    en: {
      delimiters: {
          thousands: ',',
          decimal: '.'
      },
      abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
      },
      ordinal : function (number) {
          return number === 1 ? '' : '';
      },
      currency: {
          symbol: '€'
      }
    }
  }

  numeral.register('locale', 'sr', numberFormats['sr']);
  //numeral.register('locale', 'en', numberFormats['en']);
  numeral.defaultFormat('0,000.00');