import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, getI18n } from 'react-i18next';
import { creditCalculatorActions } from '../_actions';
import { filterCalculationById, addRepaymentToPlan, calculateCreditCostUntilMonth, 
  getCalculationSummary, numToString } from './formulas';
import { store, history } from '../_helpers/';
import { ConfirmationDialog } from '../_components';
import FormValidator from '../_helpers/FormValidator';
import { creditCalculatorFields } from '../_validators';
import { ValidationInput } from '../_components';
import moment from "moment";
import LogoMini from '../logo-w.svg';

/*Material ui*/
import MaterialTable/*, {MTableBodyRow}*/ from 'material-table';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

class PaymentPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(creditCalculatorFields);
    this.state = {
      calculation: undefined,
      createPaymentPlan: false,
      submitted: false,
      validation: this.validator.valid(),
      repaymentValue: '',
      repaymentFee: '',
      repaymentDialog: false,
      repaymentDate: undefined,
      rowData: {},
      futerData: {},
    };
    moment.locale(getI18n().language);

    this.unsubscribe = this.unsubscribe.bind(this); //bind function once
    this.handleChange = this.handleChange.bind(this);
    this.handleAddRepaymentToPlan = this.handleAddRepaymentToPlan.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.openRepaymentDialog = this.openRepaymentDialog.bind(this);
    this.closeRepaymentDialog = this.closeRepaymentDialog.bind(this);
    this.handeRowClick = this.handeRowClick.bind(this);

  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitted: false });
  }
  openRepaymentDialog(rowData) {
    if (rowData.repayment_not_possible !== true) {
      this.setState({ repaymentDialog: true, rowData });
    }
  }
  closeRepaymentDialog(e) {
    this.setState({ repaymentDialog: false, rowData:undefined });
  }


  handleDateChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddRepaymentToPlan(e) {
    e.preventDefault();
    const calculation = this.state.calculation;
    this.setState({ submitted: true });
    const validation = this.validator.validate(this.state, 'repaymentFields');
    if (validation.isValid) {
      calculation.payment_plan = addRepaymentToPlan(this.state);
      this.setState({ calculation, submitted: false, rowData:undefined, repaymentDialog: false,  repaymentValue: '', repaymentFee: '', });
      this.props.dispatch(creditCalculatorActions.saveCalculation(calculation));
    }
  };

  handeRowClick(e, rowData) {
    let selectedRows = document.getElementsByClassName('selected-row');
    for (let i = 0; i < selectedRows.length; i++) {
      selectedRows[i].classList.remove('selected-row');
    }
    e.target.parentElement.className += ' selected-row';
    
    if (this.state.futerData && this.state.futerData.monthNo === (rowData.i+1)) {
      this.setState({futerData: {}});
    } else this.setState({futerData: calculateCreditCostUntilMonth(this.state.calculation, rowData.i+1)});
    
  }
  unsubscribe() {
  }

  componentDidMount() {
    const dispatch = this.props.dispatch;
    const calculations = this.props.creditCalculator.calculations;
    if (calculations.length === 0) {
      this.props.dispatch(creditCalculatorActions.getAll());
    } else {
      let calculation = filterCalculationById(this.props);
      dispatch(creditCalculatorActions.selectCalculation(calculation));
      this.setState({ calculation, createPaymentPlan: (calculation.firstPaymentDate === undefined) });
    }
    this.unsubscribe = store.subscribe(() => {
      if (this.props.creditCalculator.calculations.length > 0) {
       let calculation = filterCalculationById(this.props);
       if (this.props.creditCalculator && ! this.props.creditCalculator.selectedCalculation) dispatch(creditCalculatorActions.selectCalculation(calculation));
       this.setState({ calculation });
      }
    })
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleResetPaymentPlan(){
    history.push('/credit-calculator-list?new-payment-plan');
  }


  render() {
    const { t, creditCalculator } = this.props;
    const { calculation, repaymentValue, repaymentFee, submitted, futerData } = this.state;
    let validation = submitted ?      
    this.validator.validate(this.state, 'repaymentFields') : 
    this.state.validation                   
    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }
 
    return (
      <div className="gridlist__container payment-plan-container flex column-xs">
        {calculation &&
        <div className="base-plan">
          <header className="calculator-view">
            <div className="title-bar">
              <strong>{calculation.calculationName} </strong>
            </div>
            <div className="navtabs not-clickable flex row-xs">
              <div className="flex row-xs">
                <div className="navtabs__tab">
                  <div className="label">{t('Property Price')}</div>
                  <p className="amount">{numToString(calculation.propertyPrice)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Participation')}</div>
                  <p className="amount">{numToString(calculation.participation)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Loan')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).loan)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('NIR')}</div>
                  <p className="amount">{numToString(calculation.nominal_interest_rate)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Installments')}</div>
                  <p className="amount">{calculation.installment_num}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Installment')}</div>
                  <p className="amount">{numToString(calculation.installment_price)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Start Cost')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).initial_costs)}</p>
                </div>
                <div className="navtabs__tab">
                  <div className="label">{t('Additional Costs')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).additional_costs)}</p>
                </div>
              </div>
              <div className="row-xs">
                <div className="costs-field">
                  <div className="label">{t('Expenses')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).costs)}</p>
                </div>
                <div className="total-field">
                  <div className="label">{t('Interest')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).bank_interest)}</p>
                </div>
                <div className="total-sub-field">
                  <div className="label">{t('Loan Price')}</div>
                  <p className="amount">{numToString(getCalculationSummary(calculation).loan_price)}</p>
                </div>
              </div>
            </div>
          </header>
        </div>}
        {calculation && creditCalculator.saving !== true  && <MaterialTable
          columns={[
            { title: t('Month'), field: 'i', type: 'numeric', render: rowData => rowData.i + 1, width: 75},
            { title: t('Date'), field: 'payment_day', type: 'date', render: rowData => moment(rowData.payment_day).format('L') },
            { title: t('Installment'), field: 'installment_price', type: 'numeric', render: rowData => numToString(rowData.installment_price) },
            { title: t('Paid Interest'), field: 'interest_paid', type: 'numeric', render: rowData => numToString(rowData.interest_paid) },
            { title: t('Paid Loan'), field: 'principal_paid', type: 'numeric', render: rowData => numToString(rowData.principal_paid) },
            { title: t('Total Owed'), field: 'debt', type: 'numeric', render: rowData => numToString(rowData.debt) },
          ]}
          actions={[
            rowData => ({
              icon: 'euro_symbol',
              iconProps: {classes: {root: `euro-symbol status-${rowData.repayment_not_possible ? 'disabled' : 'enabled' }`}},
              tooltip: rowData.repayment_not_possible !==true ? t('Add Repayment') : t('You\'ve already added early repayment after this month.'),
              onClick: (event, rowData) => {
                if (rowData.repayment_not_possible !== true)
                  this.openRepaymentDialog(rowData);
              },
              disabled: rowData.repayment_not_possible === true,
            }),
          ]}

          options={{
            pageSize: 12,
            sorting: false,
            pageSizeOptions: [12, 24, 36, calculation.installment_num],
            actionsColumnIndex: -1,
            rowStyle: rowData => {
              if (rowData.repaymentValue) {
                return { backgroundColor: '#D2DFFF'};
              }
            }
          }}
          onRowClick={this.handeRowClick}
          data={calculation.payment_plan}
          title={t('Payment simulation for') + ` ${calculation.calculationName}`}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} / {count}',
              previousTooltip: t('Previous Page'),
              nextTooltip: t('Next Page'),
              labelRowsPerPage: t('rows'),
              labelRowsSelect: t('rows')
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected'
            },
            header: {
              actions: t('Actions'),
            },
            body: {
              emptyDataSourceMessage: t('No records to display'),
              filterRow: {
                filterTooltip: 'Filter'
              }
            }
          }}
        />}
        {creditCalculator && (creditCalculator.loading || creditCalculator.saving === true )&&
          <MuiThemeProvider theme={darktheme}>
            <div className="loader-wrapper">
              <CircularProgress size="50px" color="primary" />
            </div>
          </MuiThemeProvider>
        }

        <ConfirmationDialog open={this.state.repaymentDialog} title={t('Early Payment')} contentWrapper="true"
          onClose={this.closeRepaymentDialog}
          onConfirm={this.handleAddRepaymentToPlan}>

          <ValidationInput type="number" placeholder={t('Repayment value')} name="repaymentValue"
              value={repaymentValue}
              label={t('Repayment Value')}
              {...inputProps}
          />

          <ValidationInput type="number" placeholder={t('Repayment fee')} name="repaymentFee"
              value={repaymentFee}
              label={t('Repayment Fee')}
              {...inputProps}
          />

        </ConfirmationDialog>


        <footer className="main-bottom-footer calculator-view column MuiPaper-elevation3">
          <div className="row footer-inner">
            <a className="home-link hidden-xs" href="/"><img className="app-logo mini" width="60" height="60" src={LogoMini} alt="Logo" /></a>


            {futerData && futerData.total &&
            <div className="row-lg right-group scroller">
              <div className="month-pointer column">
                <strong>{t('With downpayment')}
                  <span className="order-no"> {futerData.monthNo} </span>
                  {t('your total cost was')}:
                </strong>
              </div>
              <div className="row-xs">
                <div className="participation-field">
                  <span className="label">{t('Participation')}</span>
                  <span className="amount">{numToString(futerData.participation)}</span>
                </div>
                <div className="costs-field">
                  <span className="label">{t('Expenses')}</span>
                  <span className="amount">{numToString(futerData.totalAditionalAndInitial)}</span>
                </div>
                <div className="loan-field">
                  <span className="label">{t('Principal Paid')}</span>
                  <span className="amount">{numToString(futerData.totalPrincipal)}</span>
                </div>
                <div className="total-field">
                  <span className="label">{t('Interest Cost')}</span>
                  <span className="amount">{numToString(futerData.totalInterest)}</span>
                </div>
                <div className="total-costs-field">
                  <span className="label">{t('Total Loan Cost')}</span>
                  <span className="amount">{numToString(futerData.total)}</span>
                </div>
                  <div className="super-total-field">
                    <span className="label">
                      {t('Total Current Value')} <Tooltip title={t('Total Current Value Hint')}><InfoIcon/></Tooltip>
                    </span>
                    <span className="amount">{numToString(futerData.mortgageValue)}</span>
                  </div>
              </div>
            </div>
            }

            <Button className="reset-payment-button"
              variant="contained"
              color="secondary"
              onClick={this.handleResetPaymentPlan}>
              {t('Reset Payment Plan')}
            </Button>

          </div>
        </footer>

      </div>
    );
  }
}


function mapStateToProps(state) {
  const { authentication, creditCalculator } = state;
  return {
    authentication,
    creditCalculator
  };
}

const connectedPaymentPlanPage = withTranslation('global')(connect(mapStateToProps)(PaymentPlanPage));
export { connectedPaymentPlanPage as PaymentPlanPage };

const darktheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFFFFF',
    },
  },
});