import { creditCalculatorConstants } from '../_constants';

const initialState = {calculations:[], input:{stage: creditCalculatorConstants.CREDIT_CALCULATOR_PROPERTY_PRICE_STAGE}}

export function creditCalculator(state = initialState, action) {
  switch (action.type) {
    case creditCalculatorConstants.ADD_INPUT:
      return {
        ...state,
        input: {...state.input, ...action.input}
      };
    case creditCalculatorConstants.REMOVE_INPUT:
      return {
        ...state,
        input: {}
      };  
    case creditCalculatorConstants.SAVE_CALCULATION_SUCCESS:
      return {
        ...state,
        saving: false 
      };
    case creditCalculatorConstants.SAVE_CALCULATION_REQUEST:
      return {
        ...state,
        saving: true
      };
    case creditCalculatorConstants.SAVE_CALCULATION_FAILURE:
      return {
        ...state,
        saving: false
      };
    case creditCalculatorConstants.DELETE_CALCULATION_SUCCESS:
      return {
        ...state,
        calculations: state.calculations.filter(calculation => calculation._id !== action.calculation_id),
        deleting: false 
      };
    case creditCalculatorConstants.ADD_PAYMENT_TO_CALCULATION:
       const newCaclulations = state.calculations.map(calculation => {
         if (calculation._id !== action.calculation._id) return calculation;
          else return action.calculation
       }) 
      return {
        ...state,
        calculations: newCaclulations,
      };
    case creditCalculatorConstants.DELETE_CALCULATION_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case creditCalculatorConstants.DELETE_CALCULATION_FAILURE:
      return {
        ...state,
        deleting: false
      };     
    case creditCalculatorConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      }; 
    case creditCalculatorConstants.GETALL_SUCCESS:
      return {
        ...state,
        calculations: Array.isArray(action.calculations) ? action.calculations : [action.calculations],
        loading: false
      }; 
    case creditCalculatorConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false
      }; 
    case creditCalculatorConstants.SELECT_CALCULATION_SUCCESS:
        return {
          ...state,
          selectedCalculation: action.calculation
        }; 

    default:
      return state
  }
}