import moment from "moment";
const numeral = require('numeral');


function getBaseLog(x, y) {
    return Math.log(y) / Math.log(x);
 }

export const calcMonthlyInstalment = (nks, amount, participation, no_months) => {
    if (!nks || !amount || !no_months) return '';
    nks = getNum(nks);
    amount = getNum(amount);
    participation = getNum(participation);
    no_months = parseInt(getNum(no_months),10);
    const rate = nks/(100*12);
    amount = amount - participation;
    return ((amount * rate)/(1- Math.pow(1+rate, -no_months))); 
}

export const calcNoMonthsPerFixInstalment = (nks, amount ,participation, monthlyInstallmentAmount) => {
    nks = getNum(nks);
    amount = getNum(amount);
    participation = getNum(participation);
    monthlyInstallmentAmount = getNum(monthlyInstallmentAmount);
    const rate = nks/(100*12);
    amount = amount - participation;
    return Math.round(-getBaseLog(1+rate, 1 - (amount*rate)/monthlyInstallmentAmount)) || '';
}

export const getYearDays = (year) => {
  let isLeap = (year) => new Date(year, 1, 29).getDate() === 29;
  if (isLeap(year)){
    return 366 
  } else {
    return 365
  }
}

export const filterCalculationById = (props) => {
  const calculations = props.creditCalculator.calculations;
  const { match: { params } } = props;
  let calculation = {};
  if (calculations.length > 0) calculation = calculations.filter((calculation, index) => {
    calculation.index = index;
    return calculation._id === params.calculation_id;
  })[0];
  if (calculation) {
    return calculation;
  }
}

export const getNewPaymentPlan = (calculation) => {
  if (! calculation) return;
  const payment_plan = [];
  calculation.start_day || (calculation.start_day = moment(new Date()));
  for (let i = 0; i < calculation.installment_num; i++) {
    let payment_day = moment(calculation.start_day).add(i, 'months')
    let debt_before = i === 0 ? (calculation.propertyPrice - calculation.participation) : payment_plan[i - 1].debt;
    let next_payment = moment(payment_day).add(1, 'months');
    let num_days = next_payment.diff(payment_day, 'days');
    let interest_paid = (debt_before * num_days * calculation.nominal_interest_rate) / (100 * getYearDays(payment_day.year()));//kamata
    let principal_paid = calculation.installment_price - interest_paid;// isplacena glavica
    let debt = debt_before - principal_paid;//dug

    payment_plan.push({
      i, payment_day: payment_day, interest_paid, principal_paid, debt_before, debt, 
      installment_price: calculation.installment_price
    });
  }
 return payment_plan;
}
export const addRepaymentToPlan = (state) => {
  const { calculation, rowData } = state;
  let { repaymentValue, repaymentFee } = state;
  repaymentValue = getNum(repaymentValue);
  repaymentFee = getNum(repaymentFee) || 0;
  const repaymentValueNeto = repaymentValue - repaymentFee;
  const repaymentMonthNo = rowData.i;
  const remaindInstallmentNo = calculation.installment_num - repaymentMonthNo - 1;
  
  const paymentPlanNew = [];
  
  for (let i = 0; i < repaymentMonthNo; i++) {
    const {debt, debt_before, installment_price, interest_paid, payment_day, principal_paid} = calculation.payment_plan[i];
    let {repaymentValue, repaymentFee} = calculation.payment_plan[i];
    paymentPlanNew.push({i, debt, debt_before, installment_price, interest_paid, payment_day, principal_paid, 
      repaymentValue, repaymentFee, repayment_not_possible: true});
  }
  calculation.payment_plan[repaymentMonthNo].installment_price += repaymentValue;
  calculation.payment_plan[repaymentMonthNo].interest_paid += repaymentFee;
  calculation.payment_plan[repaymentMonthNo].principal_paid += repaymentValueNeto;
  calculation.payment_plan[repaymentMonthNo].debt -= repaymentValueNeto;
  calculation.payment_plan[repaymentMonthNo].repayment_not_possible = true;
  calculation.payment_plan[repaymentMonthNo].repaymentValue = repaymentValue;
  calculation.payment_plan[repaymentMonthNo].repaymentFee = repaymentFee;
  const newDebt = calculation.payment_plan[repaymentMonthNo].debt;
  const newInstallmentPrice = parseFloat(calcMonthlyInstalment(calculation.nominal_interest_rate, newDebt, 0, remaindInstallmentNo));
 
  delete calculation.payment_plan[repaymentMonthNo]._id;
  delete calculation.payment_plan[repaymentMonthNo].tableData;
  paymentPlanNew.push(calculation.payment_plan[repaymentMonthNo]);

  const paymentPlanNew2 = getNewPaymentPlan({
    start_day: calculation.payment_plan[repaymentMonthNo+1].payment_day,
    propertyPrice: newDebt,
    participation: 0,
    nominal_interest_rate: calculation.nominal_interest_rate,
    installment_price: newInstallmentPrice,
    installment_num: remaindInstallmentNo
  })

  for (let i = 0; i < paymentPlanNew2.length; i++) {
    const {debt, debt_before, installment_price, interest_paid, payment_day, principal_paid} = paymentPlanNew2[i];
    paymentPlanNew.push({i: repaymentMonthNo + i +1, debt, debt_before, installment_price, interest_paid, payment_day, principal_paid});
  }
  
  return paymentPlanNew;
}

export const calculateCreditCostUntilMonth = (calculation, monthNo) => {
  if (! calculation || ! calculation.payment_plan) return '';
  let totalInterest = 0;
  let totalPrincipal = 0;
  let totalAditional = 0;
  for (let i = 0; i < monthNo; i++) {
    totalInterest += calculation.payment_plan[i].interest_paid;
    totalPrincipal += calculation.payment_plan[i].principal_paid;
  }
  for (let i = 0; i < calculation.additional_costs.length; i++) {
    let additional = calculation.additional_costs[i];
    if (additional.period === 'm') totalAditional += (additional.value * monthNo);
  }

  let totalAditionalAndInitial =  Math.round(totalAditional) + calculation.initial_costs.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
  totalAditionalAndInitial = totalAditionalAndInitial || 0;

  const total = Math.round(totalInterest+totalAditionalAndInitial+totalPrincipal+calculation.participation);
  return {
    monthNo,
    totalInterest: Math.round(totalInterest),
    totalPrincipal: Math.round(totalPrincipal),
    totalAditionalAndInitial: totalAditionalAndInitial,
    participation: calculation.participation,
    total,
    mortgageValue: Math.round(calculation.propertyPrice - calculation.participation - totalPrincipal + total),  //preostali iznos glavnice + ukupni troškovi do tog trenutka
  }
}

export const getCalculationSummary = (calculation) => {
  const calculationSummary  = {};
  if (! calculation) return calculationSummary;
  if (! calculation.initial_costs || calculation.initial_costs.length === 0) calculationSummary.initial_costs =  0;
   else calculationSummary.initial_costs = calculation.initial_costs.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total || 0, 0);

   if (! calculation.additional_costs || calculation.additional_costs.length === 0) calculationSummary.additional_costs =  0;
     else calculationSummary.additional_costs = calculation.additional_costs.reduce(
       (accumulator, currentValue) => accumulator + currentValue.total || 0, 0);

   calculationSummary.costs = calculationSummary.initial_costs + calculationSummary.additional_costs;
   calculationSummary.loan = calculation.propertyPrice - calculation.participation;
   
   if (! calculation.payment_plan || calculation.payment_plan.length === 0) {
     //if there is no payment plan
     calculationSummary.bank_interest = calculation.installment_num * calculation.installment_price - calculationSummary.loan;
   } else {
    calculationSummary.bank_interest = calculation.payment_plan.reduce(
      (accumulator, currentValue) => accumulator + currentValue.interest_paid || 0, 0);
    }

   calculationSummary.loan_price = calculationSummary.bank_interest +  calculationSummary.costs;
   //if (typeof calculationSummary.loan_price === 'number') calculationSummary.loan_price = calculationSummary.loan_price.toFixed();
   //if (typeof calculationSummary.bank_interest === 'number') calculationSummary.bank_interest = calculationSummary.bank_interest.toFixed();
   return calculationSummary;
}


export const getNum = function(value) { return (value && numeral(value).value()) || ''};

export const numToString = function(value, format) { return (value && numeral(value).format(format)) || '0'};

export const validateNumberString = function(str) {
  const locale = numeral.options.currentLocale || 'en';
  if (locale === 'sr') {
    return /^(\d+|\d{1,3}(\.\d{3})*)(,\d+)?$/.test(str);
  } else {
    return /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(str);
  }
}

export const validateNumberWithoutSeparator = function(str) {
  const locale = numeral.options.currentLocale || 'en';
  return locale === 'sr' ? /^\d*(,\d+)?$/.test(str) : /^\d*(\.\d+)?$/.test(str);
}

export const formulas = {
    calcMonthlyInstalment,
    calcNoMonthsPerFixInstalment,
    filterCalculationById,
    getNewPaymentPlan,
    addRepaymentToPlan,
    calculateCreditCostUntilMonth,
    getCalculationSummary,
    getNum,
    numToString,
    validateNumberString,
    validateNumberWithoutSeparator
}