//import React from 'react';
import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getI18n } from 'react-i18next';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/sr";
import { dateFormat } from '../_helpers/i18n';

export const MyDatePicker = ({ ...rest }) => {
  const [locale, setLocale] = useState(getI18n().language);
  getI18n().on('languageChanged', function(lng) {
    moment.locale(lng);
    setLocale(lng);
  })
  return (
    <MuiPickersUtilsProvider locale={locale} utils={MomentUtils} libInstance={moment}>
      <DatePicker format={dateFormat[locale]} {...rest}/>
    </MuiPickersUtilsProvider>
  )
}