import React from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { userActions } from '../_actions';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import './style.scss';
import { SignupPage } from '.';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    if (this.props.logout === true) this.props.dispatch(userActions.logout());

    this.state = {
      username: '',
      password: '',
      redirectTo: this.props.location.state && this.props.location.state.from.path, //from privateRoute component
      submitted: false,
      stagePos: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password, this.state.redirectTo));
    }
  }

  handleChangeTab = (event) => {
    const stagePos = parseInt(event.currentTarget.getAttribute('data-tab-login'));
    this.setState({ stagePos });
  };

  render() {
    const { loggingIn, t } = this.props;
    const { username, password, submitted, stagePos } = this.state;

    const navtabsClasses = (i) => {
      return ('navtabs__tab ' + (stagePos === i ? 'active' : ''));
    }

    return (
      <div className="flex login-view column">
        <div className="talking-header column">
          <h2>{t('Welcome')}</h2>
          <div className="navtabs" value={stagePos}>
            <div className={navtabsClasses(0)} data-tab-login="0" onClick={this.handleChangeTab}>
              <div>{t('You already have an account?')}</div>
            </div>
            <div className={navtabsClasses(2)} data-tab-login="2" onClick={this.handleChangeTab}>
              <p>{t('You want to create an account')}</p>
            </div>
          </div>
        </div>

        {stagePos === 0 &&
        <div className="content-wrapper">
          <div className="form-wrapper">
            <form className="login-form" name="form" onSubmit={this.handleSubmit}>
              <FormControl error={submitted && !username}>
                <InputLabel htmlFor="username">{t('Username')}</InputLabel>
                <Input name="username"
                  value={username}
                  onChange={this.handleChange}
                />
                {submitted && !username &&
                  <FormHelperText id="component-error-text">{t('Username is required')}</FormHelperText>
                }
              </FormControl>
              <FormControl error={submitted && !username}>
                <InputLabel htmlFor="password">{t('Password')}</InputLabel>
                <Input name="password"
                  type="password"
                  value={password}
                  onChange={this.handleChange}
                />
                {submitted && !password &&
                  <FormHelperText id="component-error-text">{t('Password is required')}</FormHelperText>
                }
              </FormControl>
              <footer>
                <Button name="submit-login-btn" variant="contained" type="submit" color="primary">
                  {t('Login action')}
                  {loggingIn &&
                    <CircularProgress size="20px" color="primary" />
                  }
                </Button>
                <div><Button color="secondary" href="/user/forgot-password">{t('Forgot password')}</Button></div>
              </footer>
            </form>
          </div>
        </div>
        }
        {stagePos === 2 && <SignupPage /> }  

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

const connectedLoginPage = withTranslation('global')(connect(mapStateToProps)(LoginPage));
export { connectedLoginPage as LoginPage }; 