import { creditCalculatorConstants } from '../_constants';
import { creditCalculatorService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const creditCalculatorActions = {
  addInput,
  removeInput,
  getAll,
  saveCalculation,
  deleteCalculation,
  addPaymentToCalculation,
  selectCalculation
};

function addInput(input) {
  return { type: creditCalculatorConstants.ADD_INPUT, input };
}
function removeInput() {
  return { type: creditCalculatorConstants.REMOVE_INPUT };
}
function saveCalculation(calculation, successUrl, removeInput) {
  return dispatch => {
    dispatch(request());

    creditCalculatorService.saveSingle(calculation)
      .then(
        calculationResponse => {
          dispatch(success(calculationResponse));
          ! removeInput && dispatch(creditCalculatorActions.removeInput());
          successUrl && history.push(successUrl);
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: creditCalculatorConstants.SAVE_CALCULATION_REQUEST } }
  function success(calculation) { return { type: creditCalculatorConstants.SAVE_CALCULATION_SUCCESS, calculation } }
  function failure(error) { return { type: creditCalculatorConstants.SAVE_CALCULATION_FAILURE, error } }

}
function deleteCalculation(id) {
  return dispatch => {
    dispatch(request());

    creditCalculatorService.removeSingle(id)
      .then(
        calculationResponse => {
          dispatch(success(id));

          dispatch(alertActions.success(calculationResponse));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request() { return { type: creditCalculatorConstants.DELETE_CALCULATION_REQUEST } }
  function success(calculation_id) { return { type: creditCalculatorConstants.DELETE_CALCULATION_SUCCESS, calculation_id } }
  function failure(error) { return { type: creditCalculatorConstants.DELETE_CALCULATION_FAILURE, error } }

}

function addPaymentToCalculation(calculation) {
  return dispatch => {
    dispatch({
      type: creditCalculatorConstants.ADD_PAYMENT_TO_CALCULATION,
      calculation: calculation
    })
  }
}
/*optional calculation_id as parameter then get only this calculcation*/
function getAll(calculation_id) {
  return dispatch => {
    dispatch(request());

    return creditCalculatorService.getAll(calculation_id)
      .then(
        calculations => dispatch(success(calculations)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: creditCalculatorConstants.GETALL_REQUEST } }
  function success(calculations) { return { type: creditCalculatorConstants.GETALL_SUCCESS, calculations } }
  function failure(error) { return { type: creditCalculatorConstants.GETALL_FAILURE, error } }
}

function selectCalculation(calculation){
  return dispatch => {
    if(calculation && calculation._id) {
      dispatch({
        type: creditCalculatorConstants.SELECT_CALCULATION_SUCCESS,
        calculation
      })
    } else {
      dispatch({
        type: creditCalculatorConstants.SELECT_CALCULATION_ERROR,
        calculation: undefined
      })
    }
  }
}