import React from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, getI18n } from 'react-i18next';
import { userActions } from '../_actions';
//import { FormValidator } from '../_helpers';
import FormValidator from '../_helpers/FormValidator';
import { signupFields } from '../_validators';
import { ValidationInput, PageModal } from '../_components';

import CircularProgress from '@material-ui/core/CircularProgress';

/*Material ui*/
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
//see https://material-ui.com/components/checkboxes/

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    //this.props.dispatch(userActions.logout());
    localStorage.removeItem('user'); //same as logout action

    this.validator = new FormValidator(signupFields);

    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      termsOfServiceAccepted: false,
      privacyPolicyAccepted: false,
      legalContent:'',
      isOpenTermsOfService: false,
      isOpenPrivacyPolicy: false,

      submitted: false,
      validation: this.validator.valid(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleDialogCloseTS = this.handleDialogCloseTS.bind(this);
    this.handleDialogClosePP = this.handleDialogClosePP.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleChangeCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  handleOpenDialog(page) {
    if (page === 'Terms-of-Use') {
      this.setState({isOpenTermsOfService:true});
    } else if (page === 'Privacy-Policy') {
      this.setState({isOpenPrivacyPolicy:true});
    }
  
    userActions.getPublicPage(getI18n().language, page).then((response) => {
      this.setState({legalContent: response})
    })

  };
  handleDialogCloseTS(event) {
    this.setState({isOpenTermsOfService: false, termsOfServiceAccepted: event==='ok'? true : false});
  }
  handleDialogClosePP(event) {
    this.setState({isOpenPrivacyPolicy:false, privacyPolicyAccepted: event==='ok'? true : false});
  }
  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, email, password, firstName, lastName } = this.state;
    const { dispatch } = this.props;
    const validation = this.validator.validate(this.state);
    if (validation.isValid) {
      dispatch(userActions.signup({ username, email, password, firstName, lastName }));
    }
  }

  render() {
    const { t, signingIn } = this.props;
    const { username, email, password, confirmPassword, firstName, lastName, termsOfServiceAccepted, 
      privacyPolicyAccepted, submitted, isOpenTermsOfService, legalContent, isOpenPrivacyPolicy } = this.state;

    let validation = submitted ?       // if the form has been submitted at least once
      this.validator.validate(this.state) :   // then check validity every time we render
      this.state.validation;                   // otherwise just use what's in state

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }

    return (
      <div className="column flex signup-view">
        <div className="content-wrapper column">
          <form name="signup_form" onSubmit={this.handleSubmit}>

            <ValidationInput placeholder={t('John')} name="firstName"
              label={t('First Name')}
              value={firstName}
              {...inputProps}
            />

            <ValidationInput placeholder={t('Smith')} name="lastName"
              value={lastName}
              label={t('Last Name')}
              {...inputProps}
            />

            <ValidationInput placeholder={t('Username hint')} name="username"
              value={username}
              label={t('Username')}
              {...inputProps}
            />

            <ValidationInput placeholder={t('Email hint')} name="email"
              value={email}
              label={t('Email')}
              {...inputProps}
            />

            <ValidationInput type="password" placeholder={t('Password Hint')} name="password"
              value={password}
              label={t('Password')}
              {...inputProps}
            />

            <ValidationInput type="password" placeholder={t('Confirm Password Hint')} name="confirmPassword"
              value={confirmPassword}
              label={t('Confirm Password')}
              {...inputProps}
            />

            <FormLabel component="legend">{t('Please read our Terms of Use and Privacy Policy and accept them if you agree')}:</FormLabel>
            <FormGroup aria-label="position" row>
            <FormControlLabel control={<Checkbox checked={termsOfServiceAccepted} onChange={this.handleChangeCheckbox} 
              name="termsOfServiceAccepted" color="primary" />}
              label={<Link component="button" variant="body2" onClick={()=>this.handleOpenDialog('Terms-of-Use')}>{t('Terms of Use')}</Link>}
           />
            <FormControlLabel control={<Checkbox checked={privacyPolicyAccepted} onChange={this.handleChangeCheckbox} 
              name="privacyPolicyAccepted" color="primary" />}
              label={<Link component="button" variant="body2" onClick={()=>this.handleOpenDialog('Privacy-Policy')}>{t('Privacy Policy')}</Link>}
           />
            </FormGroup>

            <PageModal isOpen={isOpenTermsOfService} handleClose={this.handleDialogCloseTS} ok={t('Agree')} cancel={t('Disagree')}>
              <div dangerouslySetInnerHTML={{__html: legalContent}}></div>
            </PageModal>

            <PageModal isOpen={isOpenPrivacyPolicy} handleClose={this.handleDialogClosePP} ok={t('Agree')} cancel={t('Disagree')}>
              <div dangerouslySetInnerHTML={{__html: legalContent}}></div>
            </PageModal>


            <footer className="row">
              <Button variant="contained" color="primary" type="submit" disabled={!termsOfServiceAccepted || !privacyPolicyAccepted}>{t('Create Account')}</Button>
              {signingIn &&
              <CircularProgress size="20px" color="primary" />
              }
            </footer>
          </form>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user, signingIn } = state.authentication;
  return {
    user,
    signingIn
  };
}

const connectedSignupPage = withTranslation('global')(connect(mapStateToProps)(SignupPage));
export { connectedSignupPage as SignupPage };