import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/es/TextField/TextField";
import { useTranslation } from 'react-i18next';

export const ValidationInput = ({ name, validation, submitted, endadornment, ...rest }) => {
  const { t } = useTranslation('global');
  let EndAdornmentValue = undefined;
  if (endadornment) {
    EndAdornmentValue = <InputAdornment position="end">{endadornment}</InputAdornment>
  }
  return (
    <div>
      <TextField name={name}
        InputProps={{
          endAdornment: EndAdornmentValue,
        }}
        {...rest}>
      </TextField>
      {validation[name].isInvalid && (submitted || validation[name].liveValidation) &&
        <div className="help-block">{t(validation[name].message)}</div>}
    </div>
  )
}