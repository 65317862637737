import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, getI18n } from 'react-i18next';
import moment from "moment";

/*import custom componets*/
import '../_helpers/i18n';
import { userActions } from '../_actions';
import { ConfirmationDialog } from '../_components';
import FormValidator from '../_helpers/FormValidator';
import { ValidationInput, PublicLink } from '../_components';
import { forgotPwdFields } from '../_validators';

/*import style and assets and material ui*/
import './style.scss';
import Logo from '../logo.svg';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';//
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import Settings from '@material-ui/icons/Settings';
import Help from '@material-ui/icons/Help';
import Block from '@material-ui/icons/Block';
import Description from '@material-ui/icons/Description';

const numeral = require('numeral');

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(forgotPwdFields);
    this.state = {
      right: false,
      downloadDialog: false,
      deleteUserDialog: false,
      mypassword: '',
      submitted: false,
      validation: this.validator.valid(),
    };
    if (getI18n().language.includes('sr')) {
      moment.locale('sr');
      numeral.locale('sr');
    } else {
      moment.locale('en');
      numeral.locale('en');
    }

    this.handleDownloadPersonalData = this.handleDownloadPersonalData.bind(this);
    this.handleCloseDownloadDialog = this.handleCloseDownloadDialog.bind(this);
    this.handleStartDownload = this.handleStartDownload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDeleteUserDialog = this.openDeleteUserDialog.bind(this);
    this.closeDleteUserDialog = this.closeDleteUserDialog.bind(this);
    this.deleteUserAccount = this.deleteUserAccount.bind(this);
  }
  
  isLoggedIn = () => this.props.authentication && this.props.authentication.loggedIn;
  
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitted: false });
  }
  
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  handleDownloadPersonalData() {
    this.setState({ downloadDialog: true, });
  }
  handleCloseDownloadDialog() {
    this.setState({ downloadDialog: false, });
  };
  handleStartDownload() {
    this.props.dispatch(userActions.downloadPersonalData());
    this.setState({ downloadDialog: false, });
  };
  openDeleteUserDialog() {
    this.setState({ deleteUserDialog: true, });
  }
  closeDleteUserDialog() {
    this.setState({ deleteUserDialog: false, });
  };
  deleteUserAccount() {
    //e.preventDefault();
    this.setState({ submitted: true });
    const validation = this.validator.validate(this.state, 'deleteUserStage');
    if (validation.isValid) {
      this.setState({ downloadDialog: false, });
      this.props.dispatch(userActions.deleteUserAccount({mypassword: this.state.mypassword}));
      
    }

  };
  componentDidMount(){
    if (this.props.user && this.props.authentication.loggedIn && this.props.user.accessToken) {
      this.props.dispatch(userActions.getUserInfo());
    }

  }

  render() {
    const { i18n, dispatch, user, t } = this.props;
    const { anchorEl, mypassword, submitted } = this.state;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      moment.locale(lng);
      numeral.locale(getI18n().language);
      window.location.reload();
    }

    let validation = submitted ?
    this.validator.validate(this.state, 'deleteUserStage') :
    this.state.validation

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }

    return (
      <div >
        <header className="app-header">
          <div className="app-header__inner">
            <div className="app-header__section">
              {(window.location.pathname.includes('/credit-calculator/') || window.location.pathname.includes('payment-plan')) && this.isLoggedIn() &&
                <Button href="/credit-calculator-list">
                  <ChevronLeft />
                  <span className="hidden-xs">{t('Calculations')}</span>
                </Button>
              }
              {window.location.pathname === '/credit-calculator' && !this.isLoggedIn() &&
                <Button href="/login">
                  <ChevronLeft />
                  <span>{t('Log in')}</span>
                </Button>
              }
            </div>
            <a className="app-logo-container" href="/"><img className="app-logo" height="80" width="80" src={Logo} alt="Logo" /></a>
            <div className="app-header__section">
              {this.isLoggedIn() ? (
                <div>
                  <Button
                    aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true"
                    open={Boolean(anchorEl)}
                    onClick={this.handleClick}>
                    {user.firstName} <KeyboardArrowDown />
                  </Button>
                  <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)}
                    offset="0 40"
                    onClose={this.handleClose}>
                    
                      <List>
                        <ListItem>
                          <Typography color="textSecondary" variant="caption">
                            {t('User Menu')}
                          </Typography>
                        </ListItem>
                      </List>
                      <MenuItem className="has-link" tabIndex={0} onClick={this.toggleDrawer('right', true)}>
                        <Settings /> {t('Settings')}
                      </MenuItem>
                      <MenuItem className="has-link"
                        component={Link}
                        href="mailto:info@debunker.rs">
                        <Help /> {t('Support')}
                      </MenuItem>


                      <MenuItem className="has-link">
                        <PublicLink target="_blank"  href="/legal/Terms-of-Use">
                          <Description/> {t('Terms of Use')}
                        </PublicLink>
                      </MenuItem>
                      <MenuItem className="has-link">
                        <PublicLink target="_blank"  href="/legal/Privacy-Policy">
                          <Description/> {t('Privacy Policy')}
                        </PublicLink>
                      </MenuItem>

                      <MenuItem onClick={(e) => dispatch(userActions.logout())}>
                        <Block color="secondary" /> {t('Log Out')}
                      </MenuItem>
                      <SwipeableDrawer
                        anchor="right"
                        open={this.state.right}
                        onClose={this.toggleDrawer('right', false)}
                        onOpen={this.toggleDrawer('right', true)}>
                        <div tabIndex={0} role="button"
                          onClick={this.toggleDrawer('right', false)}
                          onKeyDown={this.toggleDrawer('right', false)}>

                            <List>
                              <ListItem>
                                <Typography color="textSecondary" variant="caption">
                                  {t('General')}
                                </Typography>
                              </ListItem>
                              <MenuItem className="has-button">
                                <Link href="/user/reset-password/" color="inherit" >{t('Reset password')}</Link>
                              </MenuItem>
                              <MenuItem className="has-button">
                                <Button onClick={this.handleDownloadPersonalData} color="inherit" >{t('Download personal data')}</Button>
                              </MenuItem>
                              <MenuItem className="has-button">
                              <Button onClick={this.openDeleteUserDialog} color="inherit" >{t('Delete account')}</Button>
                              </MenuItem>
                              <Divider />
                              <List>
                                <ListItem className="has-button">
                                  <Typography color="textSecondary" variant="caption">
                                    {t('Language')}
                                  </Typography>
                                </ListItem>
                              </List>
                              <MenuItem className="has-button">
                                <Button onClick={() => changeLanguage('sr')}>{t('Serbian')}</Button>
                                <Button onClick={() => changeLanguage('en')}>{t('English')}</Button>
                              </MenuItem>
                            </List>
                        </div>
                      </SwipeableDrawer>
                    
                  </Menu>
                </div>
              ) : (
                  <div className="row-xs lang-menu">
                    <span className="text-label">{t('Language')}: </span>
                    <Button className="centered" onClick={() => changeLanguage('sr')}>{t('Serbian')}</Button>
                    <Button className="centered" onClick={() => changeLanguage('en')}>{t('English')}</Button>
                  </div>
                )}
            </div>
          </div>
        </header>

        <ConfirmationDialog open={this.state.downloadDialog} title={t('Download user data?')}
          onClose={this.handleCloseDownloadDialog}
          onConfirm={this.handleStartDownload}
          confirmLabel={'Download'}>
          {t('You can make a local copy of all your calculations at any point.')}
        </ConfirmationDialog>

        <ConfirmationDialog open={this.state.deleteUserDialog} contentWrapper="true"
          title={t('HEADS UP! Are you sure you want to delete your account?')}
          onClose={this.closeDleteUserDialog}
          onConfirm={this.deleteUserAccount}>
          <p>{t('Maybe you should save local copy of your data first?')}
            <Button variant="contained" color="primary" onClick={this.handleStartDownload}>
              {t('Download copy of user data')}
            </Button>
          </p>
          <p>{t('To proceed with deleting your account please provide your password')}</p>
          <ValidationInput type="password" placeholder={t('Your password')} name="mypassword"
              value={mypassword}
              label={t('Password')}
              {...inputProps}
            />
        </ConfirmationDialog>

      </div>

    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const user = authentication.user
  return {
    authentication,
    user,
  };
}

const connectedUserMenu = withTranslation('global')(connect(mapStateToProps)(UserMenu));
export { connectedUserMenu as UserMenu };

