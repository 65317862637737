import React from 'react';
import { Router, Redirect, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/*import custom componets*/
import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute, SnackbarContentWrapper, PublicLink } from '../_components';

/* import page components*/
import { LoginPage, SignupPage, ForgotPwdPage, UpdatePwdPage, SuccessConfirmedEmailPage } from '../UserActionPages';
import { CreditCalculatorPage, CreditCalculatorListPage, PaymentPlanPage } from '../CreditCalculatorPage';
import NotFoundPage from '../HomePage/NotFoundPage';
import {SalaryCalculatorPage} from '../SalaryCalculator'
import {UserMenu} from './UserMenu';

/*import style and assets and material ui*/
import './style.scss';
import Snackbar from '@material-ui/core/Snackbar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      pathname:''

    };
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      this.setState({ pathname: location.pathname });
      dispatch(alertActions.clear());
    });
  }
  isLoggedIn = () => this.props.authentication && this.props.authentication.loggedIn;

  handleCloseSnackbar = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    this.setState({ open: false });
  };
  componentDidMount(){

  }

  render() {
    const { alert, t, authentication} = this.props;
    return (
      <div className={'app-inner ' + window.location.pathname.replace(/^\//g,'').split('/')[0]}>
        <MuiThemeProvider theme={darktheme}>
          <UserMenu pathname={this.state.pathname}/>
          {authentication && authentication.sendingRequest && <LinearProgress color="primary" /> }
        </MuiThemeProvider>
        <Router history={history}>
          <div className="app-content">
          <Switch>
            <Route exact path="/" render={() => (<Redirect to="/credit-calculator-list" />)} />
            <PrivateRoute exact path="/credit-calculator-list" component={CreditCalculatorListPage} />
            <Route exact path="/credit-calculator/:calculation_id?" component={CreditCalculatorPage} />
            <PrivateRoute path="/payment-plan/:calculation_id" component={PaymentPlanPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/user/forgot-password" component={ForgotPwdPage} />
            <Route path="/user/reset-password/:pwdToken?" component={UpdatePwdPage} />
            <Route path="/user/successfully-confirmed-email-address" component={SuccessConfirmedEmailPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/salary-calculator" component={SalaryCalculatorPage} />
            <Route exact path="/*" component={NotFoundPage} />
          </Switch>
          </div>
        </Router>

        {/*success', 'warning', 'error', 'info*/}
        {alert.message &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            open={alert.message ? true : false}
            autoHideDuration={6000}
            onClose={this.handleCloseSnackbar}>
            <SnackbarContentWrapper variant={alert.type} className="" message={t(alert.message)} onClose={this.handleCloseSnackbar} />
          </Snackbar>}
          <footer className="copyright-line">
            <p className="left-group">
              <PublicLink target="_blank"  href="/legal/Terms-of-Use">{t('Terms of Use')}</PublicLink>
              <span> | </span>
              <PublicLink target="_blank"  href="/legal/Privacy-Policy"> {t('Privacy Policy')}</PublicLink>
            </p>
            <p className="right-group">
              {t('Please contact us at')} <a href="mailto:info@debunker.rs" color="secondary">info@debunker.rs</a> {t('if you have any questions, suggestions or other feedback.')}
            </p>
            <p>
              Ver {process.env.REACT_APP_VERSION || "ver 0.1.0-beta"}, Debunker DOO © 2020
            </p>
          </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, users } = state;
  const user = authentication.user
  return {
    authentication,
    alert,
    user,
    users
  };
}

const darktheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFFFFF',
  },
  },
});

const connectedApp = withTranslation('global')(connect(mapStateToProps)(App));
export { connectedApp as App }; 