import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { creditCalculatorActions } from '../_actions';
import { getNewPaymentPlan, getCalculationSummary, numToString } from './formulas';
import {ConfirmationDialog, MyDatePicker} from '../_components';
import { history } from '../_helpers';

/*import style and assets*/
import './style.scss';
//import photo from './assets/photo.svg'

/*Material ui*/
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


class CreditCalculatorListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialog: false,
      firstPaymentDateDialog: false,
      deleteCalculationId: null,
      firstPaymentDate: undefined,
      calculation: {}
    };

    this.handleEditCalculation = this.handleEditCalculation.bind(this);
    this.handleDeleteCalculation = this.handleDeleteCalculation.bind(this);
    this.openPaymentPlan = this.openPaymentPlan.bind(this);
    this.confirmCreatePaymentPlan = this.confirmCreatePaymentPlan.bind(this);
    this.closeCreatePaymentPlan = this.closeCreatePaymentPlan.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

  }

  handleEditCalculation(e, calculation) {
    this.props.dispatch(creditCalculatorActions.addInput({ ...calculation, maxStagePos: 6 }));
    history.push(`/credit-calculator/${calculation._id}`);
  }

  openPaymentPlan(calculation, index, e) {
    if (calculation.payment_plan === undefined || calculation.payment_plan.length === 0) {
       this.setState({firstPaymentDateDialog: true, calculation});
    } else {
      //we already have payment plan created
      history.push(`/payment-plan/${calculation._id}`);
    }
  }

  confirmCreatePaymentPlan() {
    this.setState({firstPaymentDateDialog: false});
    const payment_plan = getNewPaymentPlan(this.state.calculation);
    const updtedCalculation = {
      ...this.state.calculation,
      payment_plan
    };

    this.props.dispatch(creditCalculatorActions.addPaymentToCalculation(updtedCalculation));
    this.props.dispatch(creditCalculatorActions.saveCalculation(
      updtedCalculation, `/payment-plan/${this.state.calculation._id}`));
  };

  closeCreatePaymentPlan() {
    this.setState({firstPaymentDateDialog: false, calculation: {}});
  };
  handleDateChange(start_day) {
    this.setState({calculation: {...this.state.calculation, start_day}, firstPaymentDate: start_day});
  };


  handleDeleteCalculation() {
    const calculation_id = this.state.deleteCalculationId;
    this.setState({ deleteDialog: false });
    if (calculation_id) {
      this.props.dispatch(creditCalculatorActions.deleteCalculation(calculation_id));
    }
  }
  handleOpenDialog = (e, id) => {
    e.stopPropagation();
    this.setState({ deleteDialog: true, deleteCalculationId: id });
  };
  handleCloseDialog = () => {
    this.setState({ deleteDialog: false, deleteCalculationId: null });
  };

  componentWillMount(){
    const { creditCalculator } = this.props;
    if (this.props.location.search === '?new-payment-plan') {
      if (creditCalculator && creditCalculator.selectedCalculation) {
        creditCalculator.selectedCalculation.payment_plan = undefined;
        this.openPaymentPlan(creditCalculator.selectedCalculation, undefined, undefined);
      }
    }
  }
  componentDidMount() {
    this.props.dispatch(creditCalculatorActions.getAll());

  }
  render() {
    const { t } = this.props;
    const { creditCalculator } = this.props || [];
    const firstPaymentDate = this.state.firstPaymentDate;

    return (
      <div className="gridlist__container flex column-xs">
        <header className="row-xs flex-none">
          <h2 className="flex">{t('Calculations List')}</h2>
          <Link className="link-button" to="/credit-calculator">
            <Button variant="contained" color="primary">
              <AddCircleIcon />
              <span className="hidden-xs">
                {t('Add Calculation')}
              </span>
            </Button>
          </Link>
        </header>
        <div className="scroller flex column-xs">
          {(creditCalculator && creditCalculator.calculations.length > 0) ? (
            <div className="gridlist__container flex">
              <MaterialTable
                columns={[
                  { title: t('Calculation Name'), field: 'calculationName'},
                  { title: t('Calculation Type'), field: 'calculationName' , render: rowData => 'Stambeni | EUR' },
                  { title: t('Property Price'), field: 'propertyPrice', type: 'numeric', render: rowData => numToString(rowData.propertyPrice) },
                  { title: t('Participation'), field: 'participation', type: 'numeric' , render: rowData => numToString(rowData.participation)},
                  { title: t('Monthly Installments'), field: 'installment_num', type: 'numeric', },
                  { title: t('Monthly Installment'), field: 'installment_price',type: 'numeric', render: rowData => numToString(rowData.installment_price) },
                  { title: t('Start Cost'), field: 'installment_price', render:  rowData =>  numToString(getCalculationSummary(rowData).initial_costs), type: 'numeric'},
                  { title: t('Additional Costs'), field: 'installment_price', render:  rowData => numToString(getCalculationSummary(rowData).additional_costs), type: 'numeric'},
                  { title: t('Interest Cost'), field: 'installment_price', type: 'numeric', render: rowData => numToString(getCalculationSummary(rowData).bank_interest), },
                  { title: t('Total Loan Cost'), field: 'installment_price', type: 'numeric', render: rowData => numToString(getCalculationSummary(rowData).loan_price), },
                ]}
                actions={[
                  calculation => ({
                    icon: 'delete',
                    iconProps: {classes: {root: 'trash-icon'}},
                    tooltip: t('Delete calculation'),
                    onClick: (event, calculation) => this.handleOpenDialog(event, calculation._id),
                  }),
                  calculation => ({
                    icon:  (calculation.payment_plan && calculation.payment_plan.length > 0 ? 'grid_on' : 'play_arrow'),
                    tooltip: (calculation.payment_plan && calculation.payment_plan.length > 0 ? t('Open Payment Plan') : t('Generate Payment Plan')),
                    onClick: (event, rowData) =>  this.openPaymentPlan(calculation, rowData.tableData.id, event),
                  }),
                ]}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50],
                  actionsColumnIndex: -1,
                }}
                onRowClick={this.handleEditCalculation}
                data={creditCalculator.calculations}
                title={t('Comparation table')}
                localization={{
                  pagination: {
                      labelDisplayedRows: '{from}-{to} / {count}',
                      previousTooltip: t('Previous Page'),
                      nextTooltip: t('Next Page'),
                      labelRowsPerPage: t('rows'),
                      labelRowsSelect: t('rows')
                  },
                  toolbar: {
                      nRowsSelected: '{0} row(s) selected'
                  },
                  header: {
                      actions: t('Actions'),
                  },
                  body: {
                      emptyDataSourceMessage: t('No records to display'),
                      filterRow: {
                          filterTooltip: 'Filter'
                      }
                  }
                }}
              />
            </div>
            ) :
            (
              !creditCalculator.loading &&
              <div className="zero-calculations-state">
                  <h3>
                    {t('Would You like to')}&nbsp;
                    <Link to="/credit-calculator">
                      {t('create')}
                    </Link>
                    &nbsp;{t('your first calculation?')}
                  </h3>
              </div>
            )}
             { creditCalculator.loading &&
               <div className="scroller flex column-xs">
                 <div className="loader-wrapper">
                   <CircularProgress size="50px" color="primary" />
                 </div>
               </div>
              }
        </div>
        <footer className="gridlist__footer flex-none">
          <Link to="/credit-calculator">
            <Fab color="primary" aria-label={t('Add Calculation')}>
              <AddIcon />
            </Fab>
          </Link>
        </footer>

        <ConfirmationDialog open={this.state.deleteDialog} title={t('Remove a calculation?')}
          onClose={this.handleCloseDialog}
          onConfirm={this.handleDeleteCalculation}>
          {t('Once you delete the calculation you wont be able to restore it!')}
        </ConfirmationDialog>

        <ConfirmationDialog open={this.state.firstPaymentDateDialog} title={t('Start Payment Plan')} contentWrapper="true"
          onClose={this.closeCreatePaymentPlan}
          onConfirm={this.confirmCreatePaymentPlan}>
          <InputLabel htmlFor="firstPaymentDate">{t('First payment due')}:</InputLabel>
          <MyDatePicker name="firstPaymentDate" value={firstPaymentDate} onChange={this.handleDateChange} ></MyDatePicker>
        </ConfirmationDialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, creditCalculator } = state;
  return {
    authentication,
    creditCalculator
  };
}

const connectedCreditCalculatorListPage = withTranslation('global')(connect(mapStateToProps)(CreditCalculatorListPage));
export { connectedCreditCalculatorListPage as CreditCalculatorListPage };
