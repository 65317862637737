export const signupFields = [
    { 
        field: 'firstName', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'First Name is required'
    },
    { 
        field: 'firstName', 
        method: 'isAlphanumeric', 
        validWhen: true, 
        args: ['sr-RS@latin'],
        message: 'First Name badly formatted'
    },
    { 
        field: 'lastName', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Last Name is required'
    },
    { 
        field: 'lastName', 
        method: 'isAlphanumeric', 
        validWhen: true, 
        args: ['sr-RS@latin'],
        message: 'Last Name badly formatted'
    },
    { 
        field: 'username', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Username is required'
    },
    { 
        field: 'username', 
        method: 'isAlphanumeric', 
        validWhen: true, 
        args: ['sr-RS@latin'],
        message: 'Username hint'
    },
    { 
      field: 'email', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Email is required'
    },
    { 
      field: 'email',
      method: 'isEmail', 
      validWhen: true, 
      message: 'Email badly formatted'
    },
    { 
      field: 'password', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Password is required'
    },
    { 
      field: 'password', 
      method: 'matches', 
      //args: [/^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/],
      args: [/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,[\]{}\-_\\+:;?])(?=.{8,})/],
      validWhen: true, 
      message: 'Password must have'
    },
    { 
      field: 'confirmPassword', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Password confirmation is required'
    },
    { 
      field: 'confirmPassword', 
      method: passwordMatch,   // notice that we are passing a custom function here
      validWhen: true, 
      message: 'Password and password confirmation do not match'
    }
  ]

  function passwordMatch(confirmPassword, state) {return (state.password === confirmPassword)};