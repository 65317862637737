export const creditCalculatorConstants = {
  INITIAL_STAGE: 'CREDIT_CALCULATOR_INITIAL_STAGE',
  PROPERTY_PRICE_STAGE: 'CREDIT_CALCULATOR_PROPERTY_PRICE_STAGE',
  PARTICIPATION_STAGE: 'CREDIT_CALCULATOR_PARTICIPATION_STAGE',
  NOMINAL_RATE_STAGE: 'CREDIT_CALCULATOR_NOMINAL_RATE_STAGE',
  INITIAL_COSTS_STAGE: 'CREDIT_CALCULATOR_INITIAL_COSTS_STAGE',
  ADDITIONAL_COSTS_STAGE: 'CREDIT_CALCULATOR_ADDITIONAL_COSTS_STAGE',

  ADD_INPUT: 'CREDIT_CALCULATOR_ADD_INPUT_SUCCESS',
  REMOVE_INPUT: 'CREDIT_CALCULATOR_REMOVE_INPUT',

  GETALL_REQUEST: 'CREDIT_CALCULATOR_GETALL_REQUEST',
  GETALL_SUCCESS: 'CREDIT_CALCULATOR_GETALL_SUCCESS',
  GETALL_FAILURE: 'CREDIT_CALCULATOR_GETALL_FAILURE',

  SAVE_CALCULATION_REQUEST: 'CREDIT_CALCULATOR_SAVE_CALCULATION_REQUEST',
  SAVE_CALCULATION_SUCCESS: 'CREDIT_CALCULATOR_SAVE_CALCULATION_SUCCESS',
  SAVE_CALCULATION_FAILURE: 'CREDIT_CALCULATOR_SAVE_CALCULATION_FAILURE',

  DELETE_CALCULATION_REQUEST: 'CREDIT_CALCULATOR_DELETE_CALCULATION_REQUEST',
  DELETE_CALCULATION_SUCCESS: 'CREDIT_CALCULATOR_DELETE_CALCULATION_SUCCESS',
  DELETE_CALCULATION_FAILURE: 'CREDIT_CALCULATOR_DELETE_CALCULATION_FAILURE',

  ADD_PAYMENT_TO_CALCULATION: 'ADD PAYMENT TO CALCULATION',

  SELECT_CALCULATION_SUCCESS: 'CREDIT CALCULATOR SELECT CALCULATION SUCCESS',
  SELECT_CALCULATION_ERROR: 'CREDIT CALCULATOR SELECT CALCULATION ERROR'
};

export const availabeStages = [
  creditCalculatorConstants.PROPERTY_PRICE_STAGE,
  creditCalculatorConstants.PARTICIPATION_STAGE,
  creditCalculatorConstants.NOMINAL_RATE_STAGE,
  creditCalculatorConstants.INITIAL_COSTS_STAGE,
  creditCalculatorConstants.ADDITIONAL_COSTS_STAGE,
  creditCalculatorConstants.SAVE_STAGE,
]

export const stageNames = [
  'Loan Amount',
  'Loan Participation',
  'Interest Rate',
  'Initial Costs',
  'Additional Costs',
  'Review & Save'
]

export const stageImg = [
    'loan-amount-img',
    'participation-img',
    'interest-rate-img',
    'initial-cost-img',
    'additional-costs-img',
    'review-save-img',
]

export const stageHints = [
  'Loan Amount Hint',
  'Loan Participation Hint',
  'Interest Rate Hint',
  'Initial Costs Hint',
  'Additional Costs Hint',
  'Review & Save Hint',
]