import React from "react";
import { withTranslation } from 'react-i18next';

import Input from '@material-ui/core/Input';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
//import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import {  getNum, numToString, validateNumberString } from '../CreditCalculatorPage/formulas';

class InitialCosts extends React.Component {
  constructor(props) {
    super(props);

    const newInitialCosts = props.initial_initial_costs;
    
    //calculate totals
    for (var i in newInitialCosts) {
      if (newInitialCosts[i].value > 0 && newInitialCosts[i].unit) {
        newInitialCosts[i].unit === '%' ?
          newInitialCosts[i].total = Math.round((this.props.propertyPrice * newInitialCosts[i].value) / 100)
          : newInitialCosts[i].total = Math.round(newInitialCosts[i].value)

      }

      newInitialCosts[i].valid = true;
      newInitialCosts[i].valueStr = numToString(newInitialCosts[i].value);
      
    }
    this.state = {
      initialCosts: newInitialCosts
    };

  }

  sumTotalInitalCost = () => {
    const initialValue = 0;
    var sum = this.state.initialCosts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total || 0,
      initialValue
    );
    return sum;
  }

  handleInitialCostChange = (idx, propName) => evt => {
    const newInitialCosts = this.state.initialCosts.map((initialCost, sidx) => {
      let valid = initialCost.valid;
      if (idx !== sidx) return initialCost;
      
      if (propName === 'value') {
        valid = validateNumberString(evt.target.value);
        return { ...initialCost, value: getNum(evt.target.value), valueStr:evt.target.value, valid };
      } else return { ...initialCost, [propName]: evt.target.value, valid };
    });

    for (var i in newInitialCosts) {
      if (newInitialCosts[i].value > 0 && newInitialCosts[i].unit) {
        newInitialCosts[i].unit === '%' ?
          newInitialCosts[i].total = (getNum(this.props.propertyPrice) * newInitialCosts[i].value) / 100
          : newInitialCosts[i].total = newInitialCosts[i].value

      }
    }

    this.setState({ initialCosts: newInitialCosts });
    this.props.handle(newInitialCosts);//send state to parent component
  };



  handleAddInitialCost = () => {
    let i = 0;
    if (this.state.initialCosts.length > 0) {
      i = this.state.initialCosts[this.state.initialCosts.length-1].id + 1;
    }
    this.setState({
      initialCosts: this.state.initialCosts.concat([{ id: i, 
        name: '', 
        value: '', 
        unit: '€', 
        total: '' 
      }])
    });
  };

  handleRemoveInitialCost = idx => () => {
    const newInitialCosts = this.state.initialCosts.filter((s, sidx) => idx !== sidx);
    this.setState({
      initialCosts: newInitialCosts
    });
    this.props.handle(newInitialCosts);
  };

  render() {
    const t = this.props.t;
    return (
      <form className="gridlist">
        {this.state.initialCosts.map((initialCost, idx) => (
          <div className="gridlist__row" key={initialCost.id}>
            <div className="gridlist__cell">
              {idx + 1}.
            </div>
            <div className="gridlist__cell">
              <Input
                type="text"
                placeholder={t('Cost Name')}
                value={initialCost.name}
                onChange={this.handleInitialCostChange(idx, 'name')}
              />
            </div>
            <div className="gridlist__cell">
              <Input
                placeholder={t('Price')}
                value={initialCost.valueStr || ''}
                onChange={this.handleInitialCostChange(idx, 'value')}
              />
             {this.props.invalid && initialCost.valid === false &&
              <div className="help-block">{t('Number not valid')}</div>}
            </div>
            <div className="gridlist__cell">
              <Select
                value={initialCost.unit}
                onChange={this.handleInitialCostChange(idx, 'unit')}>
                <MenuItem value="€">€</MenuItem>
                <MenuItem value="%">%</MenuItem>
              </Select>
            </div>
            <span className="gridlist__cell row-total number">{numToString(initialCost.total)}</span>
            <div className="gridlist__cell">
              <Fab variant="round" color="secondary" aria-label="Delete Row"
                onClick={this.handleRemoveInitialCost(idx)}>
                <DeleteIcon />
              </Fab>
            </div>
          </div>
        ))}
        <div className="gridlist__row">
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell">
            <Fab color="primary" aria-label="Add" onClick={this.handleAddInitialCost}>
              <AddIcon />
            </Fab>
          </div>
        </div>
        <footer className="gridlist__row total-row">
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell"></div>
          <div className="gridlist__cell number subtotal-field">
            <span className="amount">
              {numToString(this.sumTotalInitalCost())}
            </span>
          </div>
          <div className="gridlist__cell"></div>
        </footer>
      </form>
    );
  }
}

const InitialCostsWithTranslation = withTranslation('global')(InitialCosts);
export { InitialCostsWithTranslation as InitialCosts };