import React from 'react';
import { withTranslation} from 'react-i18next';
//import { useLocation } from "react-router-dom";


class SuccessConfirmedEmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: ''
    };

  }




  render() { 
    const query = new URLSearchParams(this.props.location.search);
    const { t } = this.props;
    return (
      <div className="flex column email-verification-view">

        <div className="content-wrapper">
          <p>{t('Thank You')} {query.get('firstName')},</p>
          <p>{t('You have successfully verified Your email address')}&nbsp;
            <span className="email-field">{query.get('email')}</span>
          </p>
        </div>
      </div>
    );
  }
}



const connectedSuccessConfirmedEmailPage = withTranslation('global')(SuccessConfirmedEmailPage);
export { connectedSuccessConfirmedEmailPage as SuccessConfirmedEmailPage }; 