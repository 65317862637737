import {  getNum, validateNumberString, validateNumberWithoutSeparator } from '../CreditCalculatorPage/formulas';

export const creditCalculatorFields = [

    { 
      field: 'propertyPrice', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'You need a real estate price for the caclulation',
      validationStage: 0
    },
    { 
      field: 'propertyPrice', 
      method: checkNumeric, 
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 0
    },
    { 
      field: 'participation',
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Participation amount OR percentage is needed',
      validationStage: 1,
    },
    { 
      field: 'participation',
      method: checkNumeric, 
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 1,
    },
    { 
      field: 'participation_percent', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Participation amount OR percentage is needed',
      validationStage: 1,
    },
    { 
      field: 'participation_percent', 
      method: checkNumeric, 
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 1,
    },
    { 
      field: 'participation',
      method: participationValueTest, 
      validWhen: true, 
      message: 'Participation cannot be larger than real estate price',
      validationStage: 1
    },
    { 
      field: 'nominal_interest_rate', 
      method: checkNumericSeparatorNotAllowed,
      validWhen: true, 
      message: 'Number not valid. Only decimal allowed.',
      validationStage: 2,
      liveValidation: true
    },
    { 
      field: 'installment_num', 
      method: 'isEmpty',
      validWhen: false, 
      message: 'Needed for calculation OR add Nominal Interest Rate and wanted monthly installment',
      validationStage: 2,
    },
    { 
      field: 'installment_num', 
      method: checkNumeric,
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 2
    },
    { 
      field: 'installment_num', 
      method: isInteger,
      validWhen: true, 
      message: 'Decimal not allowed',
      validationStage: 2
    },
    { 
      field: 'installment_price', 
      method: checkNumeric,
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 2
    },
    { 
      field: 'repaymentValue',
      method: checkNumeric, 
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 'repaymentFields',
    },
    { 
      field: 'repaymentValue',
      method: repaymentValueTest, 
      validWhen: true, 
      message: 'Repayment value can\'t be greater then total owned in selected month',
      validationStage: 'repaymentFields',
    },
    { 
      field: 'repaymentFee',
      method: checkNumericWithZero, 
      validWhen: true, 
      message: 'Number not valid',
      validationStage: 'repaymentFields',
    },
  ]

  function participationValueTest(participation, state) { return (getNum(state.propertyPrice) > getNum(participation));};

  function checkNumeric(value) { return (validateNumberString(value)) && (isNaN(getNum(value)) === false) && getNum(value) > 0};
  
  function checkNumericWithZero(value) { return (value === '' || value === 0) || checkNumeric(value)};
  
  function checkNumericSeparatorNotAllowed(value) { return (validateNumberWithoutSeparator(value)) && (isNaN(getNum(value)) === false) && getNum(value) > 0};


  function isInteger(value) {return Number.isInteger(getNum(value)) && validateNumberString(value)}

  function repaymentValueTest(value,state) { return getNum(value) < state.rowData.debt}
