import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { creditCalculatorActions } from '../_actions';
import FormValidator from '../_helpers/FormValidator';
import { creditCalculatorFields } from '../_validators';
import { availabeStages, stageNames, stageHints, stageImg } from '../_constants';
import { ValidationInput } from '../_components';
import { InitialCosts } from './InitialCosts';
import { AdditionalCosts } from './AdditionalCosts';
import { calcMonthlyInstalment, calcNoMonthsPerFixInstalment, getNum, numToString } from './formulas';


/*import style and assets*/
import './style.scss';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

/*Material ui*/
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CompareArrows from '@material-ui/icons/CompareArrows';


function convertInputToNum(input) {
  return {
    propertyPrice: numToString(input.propertyPrice),
    participation: numToString(input.participation),
    participation_percent: numToString(input.participation_percent),
    nominal_interest_rate: numToString(input.nominal_interest_rate),
    installment_num: numToString(input.installment_num, '0'),
    installment_price: numToString(input.installment_price),
    additional_costs: input.additional_costs || [
      { id: 0, name: '', value: '', unit: '€', period: 'm', total: '' }
    ],
    initial_costs: input.initial_costs || [{ id: 0, name: '', value: '', unit: '€', total: '' }],
    maxStagePos: input.maxStagePos || 0,
    calculationName: input.calculationName || 'Calc#1',
  };

}


class CreditCalculatorPage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(creditCalculatorFields);
    this.state = {
      _id: null,
      calculationName: 'Calc#1',
      currency: '€',
      propertyPrice: '',
      participation: '',
      participation_percent: '',
      nominal_interest_rate: '',
      installment_num: '',
      installment_price: '',
      stagePos: 0,
      maxStagePos: 0,
      submitted: false,
      validation: this.validator.valid(),
      additional_costs: [
        { id: 0, name: '', value: '', unit: '€', period: 'm', total: '' }
      ],
      initial_costs: [{ id: 0, name: '', value: '', unit: '€', total: '' }],
      ...convertInputToNum(props.creditCalculator.input)
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleSubmitNext = this.handleSubmitNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handeInitialCosts = this.handeInitialCosts.bind(this);
    this.AdditionalCosts = this.AdditionalCosts.bind(this);
    this.sumTotalInitalCost = this.sumTotalInitalCost.bind(this);
    this.sumTotalAdditionalCost = this.sumTotalAdditionalCost.bind(this);
    this.handleSaveCalculation = this.handleSaveCalculation.bind(this);
    this.handleSaveName = this.handleSaveName.bind(this);
  }

  handleChangeTab = (event) => {
    const stagePos = parseInt(event.currentTarget.getAttribute('data-stagepos'));
    if (stagePos < this.state.maxStagePos) {
      this.setState({ stagePos });
    }
    const { dispatch } = this.props;
    const { calculationName, initial_costs, additional_costs } = this.state;
    const propertyPrice = getNum(this.state.propertyPrice);
    const participation = getNum(this.state.participation);
    const participation_percent = getNum(this.state.participation_percent);
    const nominal_interest_rate = getNum(this.state.nominal_interest_rate);
    const installment_num = getNum(this.state.installment_num);
    const installment_price = getNum(this.state.installment_price);

    dispatch(creditCalculatorActions.addInput({
      currency: '€', stage: availabeStages[stagePos], propertyPrice, participation, participation_percent, calculationName,
      nominal_interest_rate, installment_num, installment_price, initial_costs, additional_costs
    }));

  };
  set_participation_percent = () => {
    let participation_percent = numToString((getNum(this.state.participation) / getNum(this.state.propertyPrice)) * 100);
    this.setState({ participation_percent });
  }
  set_participation = () => {
    let participation = numToString((getNum(this.state.propertyPrice) / 100) * getNum(this.state.participation_percent));
    this.setState({ participation });
  }

  set_installment_price = () => {
    this.setState({
      installment_price: numToString(calcMonthlyInstalment(this.state.nominal_interest_rate, this.state.propertyPrice,
        this.state.participation, this.state.installment_num))
    })
  }
  set_installment_num = () => {
    this.setState({
      installment_num: calcNoMonthsPerFixInstalment(this.state.nominal_interest_rate, this.state.propertyPrice,
        this.state.participation, this.state.installment_price)
    })
  }
  sumTotalInitalCost = () => {
    return this.state.initial_costs.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.total || 0), 0);
  }
  sumTotalAdditionalCost = () => {
    return this.state.additional_costs.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.total || 0), 0);
  }
  handleChange(e) {
    let { name, value } = e.target;
    this.setState({ submitted: false });
    this.setState({ [name]: value }, () => {
      if (name === 'propertyPrice' && this.state.participation) {
        this.set_participation_percent();
      }
      if ((name === 'propertyPrice' || name === 'participation_percent') && this.state.installment_num) {
        this.set_installment_price();
      }

      if (name === 'participation_percent') {
        this.set_participation();
      } else if (name === 'participation') {
        this.set_participation_percent();
      } else if (name === 'nominal_interest_rate') {
        const validation = this.validator.validate(this.state, 2, true);
        this.setState({ validation });
        this.set_installment_price();
      } else if (name === 'installment_num') {
        this.set_installment_price();
      } else if (name === 'installment_price') {
        this.set_installment_num();
      }
    });
  }

  handleSaveName() {
    this.handleSubmitNext();
    if (this.props.creditCalculator && this.props.creditCalculator.input.id) {
      this.props.dispatch(creditCalculatorActions.saveCalculation({
        ...this.props.creditCalculator.input, calculationName: this.state.calculationName
      }, null, true));
    }
  }

  handeInitialCosts(newInitialCosts) {
    let initial_costs_invalid = false;
    for (var i in newInitialCosts) {
      if (newInitialCosts[i].valid === false) {
        initial_costs_invalid = true;
      }
    }
    this.setState({ initial_costs: newInitialCosts, initial_costs_invalid });
  }

  AdditionalCosts(newAdditionalCosts) {
    let additional_costs_invalid = false;
    for (var i in newAdditionalCosts) {
      if (newAdditionalCosts[i].valid === false) {
        additional_costs_invalid = true;
      }
    }
    this.setState({ additional_costs: newAdditionalCosts, additional_costs_invalid });
  }

  handleSubmitNext(e) {
    if (e) e.preventDefault();
    const { dispatch } = this.props;
    const { propertyPrice, participation, participation_percent, calculationName,
      nominal_interest_rate, installment_num, installment_price, initial_costs, additional_costs,
      initial_costs_invalid, additional_costs_invalid } = this.state;
    let stagePos = this.state.stagePos;
    this.setState({ submitted: true });
    const validation = this.validator.validate(this.state, stagePos);
    if (validation.isValid && !initial_costs_invalid && !additional_costs_invalid) {
      if (stagePos < 5) stagePos++;
      this.setState({ stagePos: stagePos, maxStagePos: Math.max(stagePos, this.state.maxStagePos) });
      dispatch(creditCalculatorActions.addInput({
        currency: '€', stage: availabeStages[stagePos], propertyPrice: getNum(propertyPrice),
        participation: getNum(participation), participation_percent: getNum(participation_percent), calculationName,
        nominal_interest_rate: getNum(nominal_interest_rate),
        installment_num: getNum(installment_num), installment_price: getNum(installment_price), initial_costs, additional_costs
      }));
      this.setState({ submitted: false });
    }

  }

  handleSaveCalculation(e) {
    this.handleSubmitNext(e);
    this.props.dispatch(creditCalculatorActions.saveCalculation(
      {
        ...this.props.creditCalculator.input,
        _id: this.props.creditCalculator.input._id,
       // calculationName: this.props.creditCalculator.input.calculationName,
        additional_costs: this.state.additional_costs,
      }, '/credit-calculator-list'
    ));
  }

  handlePrevious(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { stagePos } = this.state;
    this.setState({ stagePos: stagePos - 1 });
    dispatch(creditCalculatorActions.addInput({ stage: availabeStages[stagePos - 1] }))
    this.setState({ submitted: false });

  }

  componentDidMount() {
    const dispatch = this.props.dispatch;
    const { match: { params } } = this.props;
    const calculations = this.props.creditCalculator.calculations;

    if (calculations.length === 0) {
      dispatch(creditCalculatorActions.getAll(params.calculation_id)).then(() => {
        if (this.props.creditCalculator.calculations.length > 0 && this.props.creditCalculator.calculations[0]._id === params.calculation_id) {
          let calculation = { ...this.props.creditCalculator.calculations[0], maxStagePos: 6};
          if (!this.props.creditCalculator.input.stage) {
            this.props.dispatch(creditCalculatorActions.addInput(calculation));
            this.setState(prevState => ({prevState, ...convertInputToNum(calculation)}))
          } 
        }
      })
    }
  }


  componentDidUpdate() {
    document.getElementsByClassName('scroller navtabs')[0].scrollLeft = document.getElementsByClassName('navtabs__tab active')[0].offsetLeft - 20;

  }
  render() {
    const { authentication, creditCalculator, t } = this.props;
    const { propertyPrice, participation, stagePos, participation_percent, submitted,
      nominal_interest_rate, installment_num, installment_price, calculationName } = this.state;

    const isLoggedIn = () => authentication && authentication.loggedIn;

    let validation = submitted ?       // if the form has been submitted at least once
      this.validator.validate(this.state, stagePos) :   // then check validity every time we render
      this.state.validation                   // otherwise just use what's in state

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }
    const navtabsClasses = (i) => {
      return ('navtabs__tab ' + (stagePos === i ? 'active' : ''));
    }

    let loanCosts = (getNum(installment_num) * getNum(installment_price) - (getNum(propertyPrice) - getNum(participation))
      + (this.sumTotalInitalCost() || 0)
      + (this.sumTotalAdditionalCost() || 0)
    ) || '-';
    if (typeof loanCosts === 'number') loanCosts = numToString(loanCosts);

    let interestCost = (getNum(installment_num) * getNum(installment_price) - (getNum(propertyPrice) - getNum(participation))
    ) || '-';
    if (typeof interestCost === 'number') interestCost = numToString(interestCost);


    return (
      <div className="flex column calculator-view">
        <div className="flex column">

          <div className="row">
            <MuiThemeProvider theme={darktheme}>
              <div className="column flex name-input-group">
                {isLoggedIn() ?
                  <div>
                    <InputLabel htmlFor="calculationName">{t('Your Calculation Name')}</InputLabel>
                    <Input name="calculationName" value={calculationName}
                      placeholder={t('Calculation Name')}
                      inputProps={{ 'aria-label': [t('Calculation Name')] }} onChange={this.handleChange} onBlur={this.handleSaveName}
                    />
                  </div>
                  : <h1>{t('Demo Calculation')}</h1>
                }
              </div>
            </MuiThemeProvider>

            <div className="scroller navtabs" value={stagePos}>
              <div className={navtabsClasses(0)} data-stagepos="0" onClick={this.handleChangeTab}>
                <div className="label">{t('Property Price')}</div>
                <p className="amount">{numToString(propertyPrice) || '-'} {propertyPrice && '€'}</p>
              </div>

              <div className={navtabsClasses(1)} data-stagepos="1" onClick={this.handleChangeTab}>
                <ul className="row tabitems">
                  <li>
                    <div className="label">{t('Participation')}</div>
                    <p className="amount">{numToString(participation) || '-'} {participation && '€'}</p>
                  </li>
                  <li>
                    <div className="label">&nbsp;</div>
                    <p className="amount">{numToString(participation_percent) || '-'} {participation_percent && '%'}</p>
                  </li>
                </ul>
              </div>

              <div className={navtabsClasses(2)} data-stagepos="2" onClick={this.handleChangeTab}>
                <ul className="row tabitems">
                  <li>
                    <span className="label">{t('NIR')}</span>
                    <p className="amount">{numToString(nominal_interest_rate) || '-'} {nominal_interest_rate && '%'}</p>
                  </li>
                  <li>
                    <span className="label">{t('Monthly Installments')}</span>
                    <p className="amount">{numToString(installment_num, '0') || '-'}</p>
                  </li>
                  <li>
                    <span className="label">{t('Monthly Installment')}</span>
                    <p className="amount">{numToString(installment_price) || '-'} {installment_price && '€'}</p>
                  </li>
                </ul>
              </div>

              <div className={navtabsClasses(3)} data-stagepos="3" onClick={this.handleChangeTab}>
                <span className="label">{t('Start Cost')}</span>
                <p className="amount">{numToString(this.sumTotalInitalCost()) || '-'} {this.sumTotalInitalCost() !== 0 && '€'}</p>
              </div>

              <div className={navtabsClasses(4)} data-stagepos="4" onClick={this.handleChangeTab}>
                <span className="label">{t('Additional Costs')}</span>
                <p className="amount">{numToString(this.sumTotalAdditionalCost()) || '-'} {this.sumTotalAdditionalCost() !== 0 && '€'}</p>
                <p className="amount"></p>
              </div>
              <div className={navtabsClasses(5)} data-stagepos="5" onClick={this.handleChangeTab}>
                <span className="label">{t('Review & Save')}</span>
                <p className="amount"></p>
              </div>
            </div>
          </div>

          <header className="talking-header">
            <h2>{t(stageNames[stagePos])}</h2>
            <p>{t(stageHints[stagePos])}</p>
          </header>

          <div className="flex column">

            <div className={'content-wrapper flex column ' + stageImg[stagePos]}>

              {stagePos === 0 && <div className="form-molecule big-input">
                <ValidationInput className="form-atom" name="propertyPrice" placeholder={t('Property Price hint')}
                  label={t('Property Price')}
                  value={propertyPrice}
                  {...inputProps}
                  endadornment="€"
                />
              </div>}
              {stagePos === 1 && <div className="form-molecule row">

                <ValidationInput className="form-atom" label={t('Participation')} placeholder={t('Participation hint')}
                  name="participation"
                  value={participation}
                  {...inputProps}
                  endadornment="€"
                />

                <span className="hint-text">{t('OR')}</span>

                <ValidationInput className="form-atom mini" placeholder={t('Participation Percentage hint')}
                  label={t('Participation Percentage')}
                  name="participation_percent"
                  value={participation_percent}
                  {...inputProps}
                  endadornment="%"
                />

              </div>}
              {stagePos === 2 && <div className="interdependent-elements">
                <div className="interest-rates">
                  <div className="column">
                    <ValidationInput className="form-atom" label={t('Nominal Interest Rate')}
                      placeholder={t('Nominal Interest Rate hint')}
                      name="nominal_interest_rate"
                      value={nominal_interest_rate}
                      {...inputProps}
                      submitted={true}
                      endadornment="%"
                    />
                  </div>
                </div>
                <CompareArrows />
                <ValidationInput className="form-atom" label={t('Number of Monthly Installments')} name="installment_num"
                  placeholder={t('Number of Monthly Installments hint')}
                  value={installment_num}
                  {...inputProps}
                  endadornment={t('Months')}
                />
                <CompareArrows />
                <ValidationInput className="form-atom" label={t('Monthly Installment')} name="installment_price"
                  placeholder={t('Monthly Installment hint ')}
                  value={installment_price}
                  {...inputProps}
                  endadornment="€"
                />
              </div>}

              {stagePos === 3 && <div className="column flex costs-calc">
                <InitialCosts initial_initial_costs={this.state.initial_costs} propertyPrice={getNum(propertyPrice)} handle={this.handeInitialCosts}
                  invalid={this.state.initial_costs_invalid} />
              </div>}

              {stagePos === 4 && <div className="column flex costs-calc">
                <AdditionalCosts initial_additional_costs={this.state.additional_costs} propertyPrice={getNum(propertyPrice)}
                  installment_num={getNum(installment_num)} handle={this.AdditionalCosts} invalid={this.state.additional_costs_invalid} />
              </div>}

              {stagePos === 5 && <div className="column flex">
                <table className="dbnkr-table">
                  <thead>
                    <tr className="header">
                      <th className="cell">{t('Cost Name')}</th>
                      <th className="cell number">{t('Price')}</th>
                      <td className="cell edit"></td>
                    </tr>
                  </thead>

                  <tbody className="part">
                    <tr className="row">
                      <td className="cell">{t('Property Price')}</td>
                      <td className="cell number">{numToString(propertyPrice)}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="0" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>
                    <tr className="row">
                      <td className="cell">{t('Participation')}</td>
                      <td className="cell number">{participation}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="1" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>
                    <tr className="row">
                      <td className="cell">{t('Interest')}</td>
                      <td className="cell number">{numToString(nominal_interest_rate) || '-'} {nominal_interest_rate && '%'}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="2" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>
                    <tr className="row">
                      <td className="cell">{t('Installments')}</td>
                      <td className="cell number">{installment_num}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="2" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>


                    <tr className="subheader">
                      <td className="cell" colSpan="2">{t('Start Cost')}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="3" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>

                    {this.state.initial_costs.map((initial_cost, idx) => (
                      <tr className="row" key={initial_cost.id}>
                        <td className="cell">
                          {initial_cost.name}
                        </td>
                        <td className="cell number">
                          {numToString(initial_cost.total)}
                        </td>
                        <td className="cell edit"></td>
                      </tr>
                    ))}



                    <tr className="subheader">
                      <td className="cell" colSpan="2">{t('Additional Costs')}</td>
                      <td className="cell edit"><Button color="primary" data-stagepos="4" onClick={this.handleChangeTab}>{t('Edit')}</Button></td>
                    </tr>


                    {this.state.additional_costs.map((additional_cost, idx) => (
                      <tr className="row" key={additional_cost.id}>
                        <td className="cell">{additional_cost.name}</td>
                        <td className="cell number">{numToString(additional_cost.total)}</td>
                        <td className="cell edit"></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>}

            </div>

            <footer className={`row-xs ctas stage-${stagePos}`}>
              {stagePos > 0 && stagePos < 6 &&
                <Button color="secondary" onClick={this.handlePrevious}>
                  {t('Back')}
                </Button>}
              {stagePos < 5 &&
                <Button variant="contained" color="primary" onClick={this.handleSubmitNext} autoFocus>
                  {t('Next')}
                </Button>}
              {stagePos === 5 && isLoggedIn() &&
                <Button variant="contained" color="primary" onClick={this.handleSaveCalculation}>
                  {t('Save Calculation')}
                  {creditCalculator.saving &&
                    <CircularProgress size="20px" color="primary" />
                  }
                </Button>}
              {stagePos === 5 && !isLoggedIn() &&
                <p>
                  {t('If you want to save')}
                  <Button color="secondary" href="/login">
                    {t('login or register')}
                  </Button>
                </p>
              }
            </footer>
          </div>

          <footer className="results-footer row-xs">
            <div className="row-xs">
              <div className="participation-field">
                <span className="label">{t('Participation')}</span>
                <span className="amount">
                  {numToString(participation) || '-'}
                </span>
              </div>
              <div className="loan-field">
                <span className="label">{t('Loan')}</span>
                <span className="amount">
                  {numToString(getNum(propertyPrice) - getNum(participation)) || '-'}
                </span>
              </div>
              <div className="total-field">
                <span className="label">{t('Interest Cost')}</span>
                <span className="amount">
                  {interestCost  || '-'}
                </span>
              </div>
              <div className="super-total-field">
                <span className="label">{t('Total Loan Cost')}</span>
                <span className="amount">
                  {installment_price && installment_num && loanCosts}
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, creditCalculator } = state;
  return {
    authentication,
    creditCalculator
  };
}

const darktheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const connectedCreditCalculatorPage = withTranslation('global')(connect(mapStateToProps)(CreditCalculatorPage));
export { connectedCreditCalculatorPage as CreditCalculatorPage };


