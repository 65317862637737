import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    signup,
    getAll,
    forgotPwdRequest,
    updatePwd,
    downloadPersonalData,
    deleteUserAccount,
    getUserInfo,
    getPublicPage,
};

function login(username, password, redirectTo) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    redirectTo = redirectTo || '/';
                    history.push(redirectTo);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    window.location ='/';
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
//{firstName, lastName, username, email, password})
function signup(userRegsiterData) {
    return dispatch => {
        dispatch(request({password:'', ...userRegsiterData}));
        userService.signup(userRegsiterData)
            .then(
                user =>  {
                    dispatch(success(user))
                    history.push('/');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
    function request(user) { return { type: userConstants.SIGNUP_REQUEST, user } }
    function success(user) { return { type: userConstants.SIGNUP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SIGNUP_FAILURE, error } }
}
//send request to reset password via link in email address
function forgotPwdRequest(postData) {
  return dispatch => {
      dispatch(request(postData));
      userService.forgotPwdRequest(postData)
          .then(
              response =>  {
                  dispatch(success(response));
                  dispatch(alertActions.success(response.message));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          )
  }
  function request(postData) { return { type: userConstants.FORGOT_PWD_REQUEST, postData } }
  function success(response) { return { type: userConstants.FORGOT_PWD_SUCCESS, response } }
  function failure(error) { return { type: userConstants.FORGOT_PWD_FAILURE, error } }
}

function updatePwd(postData) {
  return dispatch => {
      dispatch(request({pwdToken: postData.pwdToken}));
      userService.updatePwd(postData)
          .then(
              response =>  {
                  dispatch(success(response));
                  history.push('/');
                  dispatch(alertActions.success(response.message));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          )
  }
  function request(postData) { return { type: userConstants.UPDATE_PWD_REQUEST, postData } }
  function success(response) { return { type: userConstants.UPDATE_PWD_SUCCESS, response } }
  function failure(error) { return { type: userConstants.UPDATE_PWD_FAILURE, error } }
}


function downloadPersonalData() {
  return dispatch => {
    dispatch(request());
    userService.downloadPersonalData()
        .then(
            response =>  {
                dispatch(success());
                dispatch(alertActions.success('User data exported successfully!'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        )
  }
  function request() { return { type: userConstants.DOWNLOAD_REQUEST } }
  function success() { return { type: userConstants.DOWNLOAD_SUCCESS } }
  function failure(error) { return { type: userConstants.DOWNLOAD_FAILURE, error } }
}

function deleteUserAccount(postData) {
  return dispatch => {
    dispatch(request());
    userService.deleteUserAccount(postData)
        .then(
            response =>  {
                dispatch(success());
                dispatch(alertActions.success('Goodbuy. User acccount deleted!'));
                dispatch(userActions.logout())
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        )
  }
  function request() { return { type: userConstants.DELETE_USER_REQUEST } }
  function success() { return { type: userConstants.DELETE_USER_SUCCESS } }
  function failure(error) { return { type: userConstants.DELETE_USER_FAILURE, error } }
}

function getUserInfo() {
  return dispatch => {
    dispatch(request());
    userService.getUserInfo()
        .then(
            userInfo =>  {
                dispatch(success(userInfo));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        )
  }
  function request() { return { type: userConstants.USER_INFO_REQUEST } }
  function success(userInfo) { return { type: userConstants.USER_INFO_SUCCESS, userInfo } }
  function failure(error) { return { type: userConstants.USER_INFO_FAILURE, error } }
}


function getPublicPage(lang, filename, filetype='html') {
  return fetch(`${process.env.REACT_APP_URL}/legal/${filename}-${lang}.${filetype}`).then((response) => response.text());
}