import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
let loggedIn = false;
if (user)  loggedIn = (user.expInRefreshToken > Date.now());
const initialState = user ? { loggedIn, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.SIGNUP_REQUEST:
      return {
        signingIn: true,
        user: action.user
      }
    case userConstants.SIGNUP_SUCCESS:
      return {
        loggedIn: true,
        signedIn: true,
        user: action.user
      }
    case userConstants.SIGNUP_FAILURE:
      return {}
    case userConstants.FORGOT_PWD_REQUEST:
    case userConstants.UPDATE_PWD_REQUEST:
    case userConstants.DOWNLOAD_REQUEST:
    case userConstants.DELETE_USER_REQUEST:
    case userConstants.USER_INFO_REQUEST:
      return {
        ...state,
        sendingRequest: true,
      }
    case userConstants.FORGOT_PWD_SUCCESS:
    case userConstants.UPDATE_PWD_SUCCESS:
    case userConstants.DOWNLOAD_SUCCESS:
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        sendingRequest: false,
      }
    case userConstants.USER_INFO_SUCCESS:
      return {
        loggedIn: true,
        signedIn: true,
        user: {...state.user, ...action.userInfo}

      }  
    case userConstants.FORGOT_PWD_FAILURE:
    case userConstants.UPDATE_PWD_FAILURE:
    case userConstants.DOWNLOAD_FAILURE:
    case userConstants.DELETE_USER_FAILURE:
    case userConstants.USER_INFO_FAILURE:
      return {
        ...state,
        sendingRequest: false,
      } 
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}