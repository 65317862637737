export const forgotPwdFields = [
  //forgot password validation rules
  { 
    field: 'email', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Email is required',
    validationStage: 'forgotPwdStage' 
  },
  { 
    field: 'email',
    method: 'isEmail', 
    validWhen: true, 
    message: 'Email badly formatted',
    validationStage: 'forgotPwdStage' 
  },

  //reset password validation rules
  { 
    field: 'newPassword', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Password is required',
    validationStage: 'updatePwdStage' 
  },
  { 
    field: 'oldPassword', 
    method: oldPasswordEmpty, 
    validWhen: true, 
    message: 'Old password is required',
    validationStage: 'updatePwdStage' 
  },
  { 
    field: 'newPassword', 
    method: 'matches', 
    //args: [/^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/],
    args: [/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,[\]{}\-_\\+:;?])(?=.{8,})/],
    validWhen: true, 
    message: 'Password must have',
    validationStage: 'updatePwdStage' 
  },
  { 
    field: 'verifyPassword', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Password confirmation is required',
    validationStage: 'updatePwdStage' 
  },
  { 
    field: 'verifyPassword', 
    method: passwordMatch,   // notice that we are passing a custom function here
    validWhen: true, 
    message: 'Password and password confirmation do not match',
    validationStage: 'updatePwdStage' 
  },
  { 
    field: 'mypassword', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Password is required',
    validationStage: 'deleteUserStage' 
  },
]

function passwordMatch(verifyPassword, state) {
  return (state.newPassword === verifyPassword)
};
function oldPasswordEmpty(oldPassword, state) {
  return Boolean(oldPassword || state.pwdToken)
};
