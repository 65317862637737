import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ValidationInput } from '../_components';
import { salaryCalculatorFields } from '../_validators';
import FormValidator from '../_helpers/FormValidator';

//google material ui
import Container from '@material-ui/core/Container';

class SalaryCalculatorPage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(salaryCalculatorFields);
    this.state = {
      employeeName: '',
      brutoSalary:'',
      netoSalary:'',
      submitted: false,
      validation: this.validator.valid(),
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitted: false })
  }
  render() {
    const { authentication, t } = this.props;
    const { employeeName, submitted, brutoSalary, netoSalary } = this.state;

    const isLoggedIn = () => authentication && authentication.loggedIn;

    let validation = submitted ? this.validator.validate(this.state, 'Initial Salary') : this.state.validation              

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }
    
    return (
      <Container fixed>
          <div className="flex column">
            <div className="content-wrapper flex column">
                {isLoggedIn() && ("Hello " + authentication.user.username) }
                <div className="form-molecule big-input">
                  <ValidationInput className="form-atom" type="text" name="employeeName" placeholder={t('Employee Name')}
                    label={t('Employee Name')}
                    value={employeeName}
                    {...inputProps}
                  />
                </div>
                <div className="form-molecule big-input">
                  <ValidationInput className="form-atom" type="number" name="brutoSalary" placeholder={t('Bruto salary')}
                    label={t('Bruto salary')}
                    value={brutoSalary}
                    {...inputProps}
                  />
                </div>
                <div className="form-molecule big-input">
                  <ValidationInput className="form-atom" type="number" name="netoSalary" placeholder={t('Neto salary')}
                    label={t('Neto salary')}
                    value={netoSalary}
                    {...inputProps}
                  />
                </div>
             </div> 
          </div>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  return {
    authentication,
  };
}

const connectedSalaryCalculatorPage = withTranslation('global')(connect(mapStateToProps)(SalaryCalculatorPage));
export { connectedSalaryCalculatorPage as SalaryCalculatorPage };