import { refreshAccessToken } from '../_helpers';
import { logout } from './user.service'

export const creditCalculatorService = {
  getAll,
  saveSingle,
  removeSingle,
};

function getAll(calculation_id) {
  const id = calculation_id || '';
  return refreshAccessToken().then( header => {
    const requestOptions = {
      method: 'GET',
      headers: header
    };
    return fetch(`${process.env.REACT_APP_API_URL}/credit-calculator/list/${id}`, requestOptions).then(handleResponse);
  });
}

function saveSingle(body) {
  return refreshAccessToken().then( header => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', ...header},
      body: JSON.stringify(body),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/credit-calculator/create`, requestOptions)
    .then(handleResponse)
    .then(response => response.message);
  });
}

function removeSingle(id) {
  return refreshAccessToken().then( header => {
    const requestOptions = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', ...header},
    };
    return fetch(`${process.env.REACT_APP_API_URL}/credit-calculator/${id}`, requestOptions)
    .then(handleResponse)
    .then(response => response.message);
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        document.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

