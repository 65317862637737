import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, getI18n} from 'react-i18next';

import FormValidator from '../_helpers/FormValidator';
import { forgotPwdFields } from '../_validators';
import { userActions } from '../_actions';
import { ValidationInput } from '../_components';
import { history } from '../_helpers';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';



class ForgotPwdPage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(forgotPwdFields);
    this.state = {
      email: '',
      submitted: false,
      sendingRequest: false,
      validation: this.validator.valid(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleCancel(e) {
    history.goBack();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    const validation = this.validator.validate(this.state, 'forgotPwdStage');
    if (validation.isValid) {
      dispatch(userActions.forgotPwdRequest({usernameOrEmail: email, lang: getI18n().language}));
    }
  }

  render() {  
    const { sendingRequest, t } = this.props;
    const { email, submitted } = this.state;

    let validation = submitted ?
      this.validator.validate(this.state, 'forgotPwdStage') :
      this.state.validation

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }
    return (
      <div className="column forgotpass-view">

        <div className="content-wrapper">

          <div className="form-wrapper">
            <form className="login-form" name="form" onSubmit={this.handleSubmit}>
             
                <ValidationInput className="form-atom mini" label={t('Email')}
                  placeholder={t('Email hint')}
                  name="email"
                  value={email}
                  {...inputProps}
                />
              
              <footer className="row">
                <Button onClick={this.handleCancel} color="primary">
                  {t('Cancel')}
                  {sendingRequest &&
                  <CircularProgress size="20px" color="primary" />
                  }
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {t('Submit')}
                  {sendingRequest &&
                  <CircularProgress size="20px" color="primary" />
                  }
                </Button>
              </footer>
            </form>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sendingRequest } = state.authentication;
  return {
    sendingRequest,
  };
}

const connectedForgotPwdPage = withTranslation('global')(connect(mapStateToProps)(ForgotPwdPage));
export { connectedForgotPwdPage as ForgotPwdPage }; 