//import config from 'config';
import { refreshAccessToken, localStorageSetUserData } from '../_helpers';

export const userService = {
  login,
  logout,
  signup,
  getAll,
  forgotPwdRequest,
  updatePwd,
  downloadPersonalData,
  deleteUserAccount,
  getUserInfo
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',//
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ usernameOrEmail: username, password })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.accessToken) {
        user.expInAccessToken += Date.now();
        user.expInRefreshToken += Date.now();
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        localStorageSetUserData(user);
      }

      return user;
    });
}

export function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function getAll() {
  return refreshAccessToken().then(function (header) {
    const requestOptions = {
      method: 'GET',
      headers: header
    };
    return fetch(`${process.env.REACT_APP_API_URL}/user/getall`, requestOptions).then(handleResponse);
  });


}

function signup(userRegsiterData) {
  const requestOptions = {
    method: 'POST',//
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userRegsiterData)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/user/register`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.accessToken) {
        user.expInAccessToken += Date.now();
        user.expInRefreshToken += Date.now();
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorageSetUserData(user);
      }

      return user;
    });
}

function forgotPwdRequest(postData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postData)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/user/forgot_password`, requestOptions)
    .then(handleResponse)
}

function updatePwd({pwdToken, ...postData}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postData)
  };
  if (pwdToken) {
    return fetch(`${process.env.REACT_APP_API_URL}/user/reset_password/${pwdToken}`, requestOptions).then(handleResponse);
  }
  else {
    return refreshAccessToken().then( header => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', ...header},
        body: JSON.stringify(postData),
      };
      return fetch(`${process.env.REACT_APP_API_URL}/user/reset_password`, requestOptions).then(handleResponse)
    });
  }
}

function downloadPersonalData() {
  return refreshAccessToken().then(function (header) {
    const requestOptions = {
      method: 'GET',
      headers: header
    };
    return fetch(`${process.env.REACT_APP_API_URL}/user/download_personal_data`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
        const user = JSON.parse(localStorage.getItem('user'));
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = (user && user.username) ? `${user.username}-app-data.json` : 'app-data.json';
        document.body.appendChild(a);
        a.click();    
        a.remove();        
    });
  });
}

function deleteUserAccount(body) {
  return refreshAccessToken().then( header => {
    const requestOptions = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', ...header},
      body: JSON.stringify(body),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions).then(handleResponse);
  });
}

function getUserInfo() {
  return refreshAccessToken().then(function (header) {
    const requestOptions = {
      method: 'GET',
      headers: header
    };
    return fetch(`${process.env.REACT_APP_API_URL}/user/info`, requestOptions).then(handleResponse);
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
