export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken && (user.expInAccessToken - Date.now()) > 60000 ) {
        return { 'Authorization': 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

export function refreshAccessToken() {

    const authHeaderValue = authHeader();

    if (! authHeaderValue || ! authHeaderValue.Authorization) {
        const requestOptions = {
            method: 'GET',
            headers: refreshTokenHeader()
        };
         return fetch(`${process.env.REACT_APP_API_URL}/user/token`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // login successful if there's a jwt token in the response
            if (response.accessToken) {
                let user = JSON.parse(localStorage.getItem('user'));
                user.expInAccessToken =  response.expInAccessToken + Date.now(); 
                user.accessToken =  response.accessToken; 
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorageSetUserData(user);
                // @todo add dispatch(success(user));
                //function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
                return { 'Authorization': 'Bearer ' + user.accessToken };
            } else {
                Promise.reject('Access token is missing. ' + response.toString());
            }
        });    
    } else {
        return new Promise(function(resolve, reject) {
           resolve(authHeaderValue);
          });
    }
}

export function localStorageSetUserData(user) {
  localStorage.setItem('user', JSON.stringify({
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    expInAccessToken: user.expInAccessToken,
    expInRefreshToken: user.expInRefreshToken,
  }));
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                localStorage.removeItem('user');
                document.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function refreshTokenHeader() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.refreshToken && (user.expInRefreshToken - Date.now()) > 60000 ) {
        return { 'Authorization': 'Bearer ' + user.refreshToken };
    } else {
        return {};
    }
}
