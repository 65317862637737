export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    SIGNUP_REQUEST: "USERS_SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "USERS_SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "USERS_SIGNUP_FAILURE",

    FORGOT_PWD_REQUEST: "USERS_FORGOT_PWD_REQUEST",
    FORGOT_PWD_SUCCESS: "USERS_FORGOT_PWD_SUCCESS",
    FORGOT_PWD_FAILURE: "USERS_FORGOT_PWD_FAILURE",

    UPDATE_PWD_REQUEST: "USERS_UPDATE_PWD_REQUEST",
    UPDATE_PWD_SUCCESS: "USERS_UPDATE_PWD_SUCCESS",
    UPDATE_PWD_FAILURE: "USERS_UPDATE_PWD_FAILURE",
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DOWNLOAD_REQUEST: 'USERS_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'USERS_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'USERS_DOWNLOAD_FAILURE',

    DELETE_USER_REQUEST: 'USERS_DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'USERS_DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'USERS_DELETE_USER_FAILURE',

    USER_INFO_REQUEST: 'USERS_USER_INFO_REQUEST',
    USER_INFO_SUCCESS: 'USERS_USER_INFO_SUCCESS',
    USER_INFO_FAILURE: 'USERS_USER_INFO_FAILURE',
};
