import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, getI18n} from 'react-i18next';

import FormValidator from '../_helpers/FormValidator';
import { forgotPwdFields } from '../_validators';
import { userActions } from '../_actions';
import { ValidationInput } from '../_components';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class UpdatePwdPage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(forgotPwdFields);
    this.state = {
      newPassword: '',
      oldPassword: '',
      verifyPassword: '',
      submitted: false,
      sendingRequest: false,
      validation: this.validator.valid(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { newPassword, verifyPassword, pwdToken } = this.state;
    const { dispatch } = this.props;
    const validation = this.validator.validate(this.state, 'updatePwdStage');
    if (validation.isValid) {
      dispatch(userActions.updatePwd({newPassword, verifyPassword, pwdToken, lang: getI18n().language}));
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({ pwdToken: params.pwdToken }); 
  }
  handleCancel(e) {
    window.location.href = '/';
  }

  render() {
    const { sendingRequest, t } = this.props;
    const { newPassword, oldPassword, verifyPassword, submitted, pwdToken } = this.state;

    let validation = submitted ?
      this.validator.validate(this.state, 'updatePwdStage') :
      this.state.validation

    const inputProps = {
      submitted,
      onChange: this.handleChange,
      validation
    }
    return (
      <div className="flex login-view column">

        <div className="content-wrapper">

          <div className="form-wrapper">
            <form className="login-form" name="form" onSubmit={this.handleSubmit}>

                <h2>{t('Resetting password')}</h2>
                
                {! pwdToken && <ValidationInput className="form-atom mini" label={t('Old password')}
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  {...inputProps}
                />}
             
                <ValidationInput className="form-atom mini" label={t('New password')}
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  {...inputProps}
                />
                <ValidationInput className="form-atom mini" label={t('Confirm Password')}
                  type="password"
                  name="verifyPassword"
                  value={verifyPassword}
                  {...inputProps}
                />
              
              <footer>
                <Button type="submit" color="primary">
                  {t('Submit')}
                  {sendingRequest &&
                    <CircularProgress size="20px" color="primary" />
                  }
                </Button>
                <Button onClick={this.handleCancel} color="primary">
                  {t('Cancel')}
                  {sendingRequest &&
                  <CircularProgress size="20px" color="primary" />
                  }
                </Button>
              </footer>
            </form>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sendingRequest } = state.authentication;
  return {
    sendingRequest,
  };
}

const connectedUpdatePwdPage = withTranslation('global')(connect(mapStateToProps)(UpdatePwdPage));
export { connectedUpdatePwdPage as UpdatePwdPage }; 